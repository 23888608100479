import { Progress, useColorModeValue, Stack, CircularProgress } from "@chakra-ui/react";
import React from "react";
import {
  useCurrentTask,
  useWorkflowProgress,
  useWorkflowInProgressOrClarificationRequested,
  useWorkflowActiveTasksCount,
} from "hooks/useCheckpoints";
import { TextOverflowTooltip } from "./TextOverflowTooltip";
import { useFeatureFlags } from "hooks";
import { useWorkflowKey } from "hooks/useWorkflows";

interface Props {
  workflowId: string;
  renderCompletedWorkflow?: () => JSX.Element;
  size?: "compact" | "full";
  progressIndicatorStyle?: "bar" | "circle";
  appendText?: string;
  align?: "start" | "end";
  showInline?: boolean;
  maxWidth?: string;
}

export const WorkflowProgress = (props: Props) => {
  const { workflowId, size, showInline, progressIndicatorStyle = "bar", appendText, align = "end", maxWidth = "unset" } = props;
  const { percent, completed, total } = useWorkflowProgress(workflowId);
  const currentTaskName = useCurrentTask(workflowId);
  const isInProgress = useWorkflowInProgressOrClarificationRequested(workflowId);
  const progressBgColor = useColorModeValue("gray.300", "gray.700");
  const progressTextColor = useColorModeValue("gray.600", "gray.300");
  const progressColor = useColorModeValue("teal", "blue");
  const progressOpacity = useColorModeValue(".4", ".3");
  const activeTasksCount = useWorkflowActiveTasksCount(workflowId);
  const workflowStatus = useWorkflowKey(workflowId, "status");
  const { parallel_child_workflows: hasParallelChildWorkflowsProgressBar } = useFeatureFlags();

  const isPaused = workflowStatus === "paused";

  if (!isInProgress) {
    return <React.Fragment />;
  } else {
    return (
      <>
        {progressIndicatorStyle === "bar" && (
          <Stack
            direction={showInline ? "row" : "column"}
            maxWidth={maxWidth}
            textAlign={align}
            spacing={showInline ? "1rem" : "0"}
            justifyContent="space-between"
            width="100%">
            <Stack direction="row" spacing=".3rem" justifyContent="space-between" width="100%">
              <TextOverflowTooltip
                label={
                  isPaused
                    ? "Paused temporarily"
                    : currentTaskName
                    ? `${currentTaskName}${
                        hasParallelChildWorkflowsProgressBar && activeTasksCount > 1 ? ` and ${activeTasksCount - 1} more tasks` : ""
                      }`
                    : "Waiting for first task to start..."
                }
                fontSize={size === "compact" ? "11px" : "sm"}
                style={{
                  paddingBottom: "0",
                  width: "100%",
                  textAlign: "start",
                }}
                color={progressTextColor}
              />
              <TextOverflowTooltip
                fontSize={size === "compact" ? "11px" : "sm"}
                style={{ paddingBottom: "0", maxWidth: appendText ? "14rem" : "7rem", width: "100%" }}
                color={progressTextColor}
                label={`${completed} of ~${total} ${appendText ?? ""}`}
              />
            </Stack>
            <Progress
              width="100%"
              alignSelf={align}
              mt={size === "compact" ? "0!important" : ".5rem!important"}
              mb={showInline ? ".5rem!important" : "0!important"}
              size={size === "compact" ? "xs" : "sm"}
              className="ch-progressbar"
              opacity={progressOpacity}
              backgroundColor={progressBgColor}
              borderRadius="full"
              isAnimated
              value={percent}
              colorScheme={isPaused ? "yellow" : progressColor}
            />
          </Stack>
        )}
        {progressIndicatorStyle === "circle" && <CircularProgress value={percent} size="19px" thickness={"10"} color="green.500" />}
      </>
    );
  }
};
