import React, { useContext, useEffect, useRef, useMemo } from "react";
import { Box, Center, Drawer, DrawerBody, DrawerContent, Stack, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { SidebarNavigation } from "./components";
import { HeaderInteractionBar } from "screens/common/components";
import { useSettingContext } from "../panels/settings/SettingsProvider";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { TypingIndicator } from "screens/thread/components/cells/components";
import {
  useIsMainAppLoading,
  useProjectConfigEntitlements,
  useSidebarNavigation,
  useUserPreference,
  useGetViewConfig,
  useConfigMap,
  useProjectParams,
  DeleteCollectionModal,
  useDeleteCollection,
  useCollectionKey,
} from "hooks";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuid } from "uuid";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { ProjectNewInput } from "./tabs/project/ProjectNewInput";
import { ProjectDetailDefaultViewHeader } from "screens/collection/views/ProjectDetailDefaultViewHeader";
import { EquityFeedbackModal } from "screens/thread/components/useEquityFeedback";

export const PROJECT_DETAIL_SECTION_SELECTOR = "project-detail-section";

export const Landing = () => {
  const { parentRoute, projectFilter, projectId, contentId, isPortfolios, isProjects } = useProjectParams();
  const { pathname } = useLocation();
  const { isSideNavigationOpen, setIsSideNavigationOpen } = useSettingContext();
  const bgColor = useColorModeValue("white", "gray.900");
  const isMobile = useBreakpointValue({ base: true, md: true, lg: false }, { fallback: "md", ssr: false });
  const isProject = isProjects || isPortfolios;
  const isMainAppLoading = useIsMainAppLoading();
  const appCountainerRef = useRef<HTMLDivElement>(null);
  const {
    onConversationOpen,
    onConversationClose,
    setConversationId,
    conversationId,
    isAnotherInputFocused,
    isEditing,
    isConversationOpen,
    setHideConversationButton,
    isUsingDefaultConversationDialog,
  } = useContext(ConversationContext);
  const { currentSidebarType: reduxNavigationType, setSidebarType } = useSidebarNavigation();
  const saveNavigationType = useUserPreference("ui_save_navigation_type");
  const userNavigationType = useUserPreference("ui_navigation_type");
  const currentSidebarType = saveNavigationType ? userNavigationType : reduxNavigationType;
  const { isAdminPanelOpen, isAddNewProjectPanelOpen, isAddToCollectionModalOpen } = useAddToCharliContext();
  const { due_diligence_project: hasDueDiligence } = useProjectConfigEntitlements();
  const configMap = useConfigMap();
  const maybePortfolioProjectCollectionType = useCollectionKey(projectFilter, "collectionType");
  const maybePortfolioProjectConversationId = useCollectionKey(projectFilter, "conversationId");
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", maybePortfolioProjectCollectionType, configMap);
  const collectionType =
    portfolioCollectionType && portfolioCollectionType?.length > 0
      ? portfolioCollectionType
      : parentRoute === "home" && hasDueDiligence
      ? "due_diligence"
      : projectFilter;
  const mainContainerRef = useRef<HTMLInputElement>(null);
  const showQAinputProjectDetail = useGetViewConfig("projectDetailQAInput", collectionType, configMap);
  const { onDeleteCollection } = useDeleteCollection();

  useEffect(() => {
    if (appCountainerRef.current) {
      appCountainerRef.current.scroll({
        top: 0,
        behavior: "auto",
      });
    }
  }, [projectId, parentRoute]);

  const hasTestEntityPreferenceEnabled = useUserPreference("ui_inject_test_entity") as boolean;
  useEffect(() => {
    const excludedPaths = ["delivery", "admin", "operations", "home"];
    const shouldHideConversationButton = excludedPaths.some((path) => pathname.includes(path)) && !hasTestEntityPreferenceEnabled;

    setHideConversationButton(shouldHideConversationButton);

    return () => {
      setHideConversationButton(false);
    };
  }, [setHideConversationButton, pathname, hasTestEntityPreferenceEnabled]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "/" && !isConversationOpen) {
        if (isAdminPanelOpen || isAddNewProjectPanelOpen || isAddToCollectionModalOpen || isAnotherInputFocused || isEditing) return;
        if (!conversationId) setConversationId(maybePortfolioProjectConversationId || contentId || uuid());
        onConversationOpen();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    maybePortfolioProjectConversationId,
    conversationId,
    isAddToCollectionModalOpen,
    isAdminPanelOpen,
    isAnotherInputFocused,
    isConversationOpen,
    isEditing,
    isAddNewProjectPanelOpen,
    onConversationClose,
    onConversationOpen,
    setConversationId,
    projectFilter,
    contentId,
    isUsingDefaultConversationDialog,
  ]);

  const debouncedSetWidth = useDebouncedCallback(() => {
    if (saveNavigationType) return;
    if (isMobile) {
      setSidebarType("hidden");
    } else {
      setSidebarType("desktop");
    }
  }, 30);
  useEffect(() => {
    window.addEventListener("resize", debouncedSetWidth);
    debouncedSetWidth();

    return () => {
      window.removeEventListener("resize", debouncedSetWidth);
    };
  }, [debouncedSetWidth]);

  const projectNewInputHeight = useMemo(() => {
    if (parentRoute === "home") return;
    if (!showQAinputProjectDetail?.showInput && projectId) return "0";
    if (isMobile) return projectId && !pathname.includes("/panel") ? "9rem" : "13rem";
    return projectId && !pathname.includes("/panel") ? "9.3rem" : "16rem";
  }, [isMobile, parentRoute, pathname, projectId, showQAinputProjectDetail?.showInput]);

  return (
    <Stack width="100vw" direction="row" spacing="0" mt="0!important" height="100vh" position="relative">
      {currentSidebarType === "hidden" ? (
        <Drawer isOpen={isSideNavigationOpen} placement="left" onClose={() => setIsSideNavigationOpen(false)}>
          <DrawerContent width={`13rem!important`}>
            <DrawerBody p="0" paddingInline={"0"} height={"100vh"}>
              <SidebarNavigation />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      ) : (
        <SidebarNavigation />
      )}
      <Stack ref={mainContainerRef} mt="0!important" marginInline="0!important" width="100%" spacing="0">
        <HeaderInteractionBar />
        <Box width="100%" height="100%" ref={appCountainerRef} overflowX="hidden" mt="0!important" backgroundColor={bgColor}>
          <Box pt="unset" height="100%">
            {isMainAppLoading ? (
              <Center backgroundColor={bgColor} zIndex={100} width="100%" height={"calc(100vh - 5rem)"}>
                <TypingIndicator />
              </Center>
            ) : (
              <Box pt="1rem">
                {isProject && (
                  <Box px="1rem">
                    {projectId && projectId?.length > 0 && !pathname.includes("/panel") && (
                      <Box className={`${PROJECT_DETAIL_SECTION_SELECTOR}`} height="4rem">
                        <ProjectDetailDefaultViewHeader collectionId={projectId} />
                      </Box>
                    )}
                    <Center width="100%" height={projectNewInputHeight} px="0">
                      <ProjectNewInput collectionType={collectionType} collectionId={projectId} />
                    </Center>
                  </Box>
                )}
                <Outlet />
              </Box>
            )}
          </Box>
        </Box>
      </Stack>
      <DeleteCollectionModal onDeleteCollection={onDeleteCollection} />
      <EquityFeedbackModal />
    </Stack>
  );
};
