import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  Icon,
  IconButton,
  Portal,
  Tooltip,
  useDisclosure,
  Tag,
} from "@chakra-ui/react";
import {
  useButtonProps,
  useCollectionHasHighlights,
  useCollectionKey,
  useConfigMap,
  useGetViewConfig,
  useMainCollectionWorkflowId,
  useProjectInPortfolios,
  useProjectParams,
  useTileProps,
  useCompanyStockFromCollectionContentsOrConversation,
  useProjectStockEquityDataFromCollectionMetadata,
  useDownloadCollectionContents,
  useCollectionMetadataIds,
  useCollectionCreatedDate,
} from "hooks";
import type { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { ConversationContext, useConversationContext } from "screens/thread/ConversationContext";
import TruncateMarkup from "react-truncate-markup";
import { ProjectDeleteButton } from "../../../../collection/views/ProjectActions/ProjectDeleteButton";
import { CollectionsFilterContext } from "screens/landing/tabs";
import ECHighlighter from "react-ec-highlighter";
import { ProjectRerunButton } from "../../../../collection/views/ProjectActions/ProjectRerunButton";
import { WorkflowProgressStepperContainer } from "screens/landing/components/WorkflowProgressStepper";
import { CollectionStatProgress } from "../../../../collection/components/CollectionStatProgress";
import { IoEllipsisVertical } from "react-icons/io5";
import { TextOverflowTooltip } from "screens/landing/components/TextOverflowTooltip";
import { StockIndicator } from "./components/StockIndicator";
import { RiskIndicator } from "./components/RiskIndicator";
import { ProjectReportPanelButton } from "screens/collection/components/ProjectReportPanelButton";
import { ProjectHeaderImage } from "./components/ProjectHeaderImage";
import { TagInput } from "screens/common/components/Tags/TagInput";
import { ProjectMoveButton } from "screens/collection/views/ProjectActions/ProjectMoveButton";
import { MoveProjectModal } from "screens/collection/views/ProjectActions/MoveProjectModal";
import { v4 as uuid } from "uuid";
import { useWorkflowStatus } from "hooks/useWorkflows";
import { BiFolder } from "react-icons/bi";
import { usePausedWorkflowModal } from "screens/collection/views/ProjectActions/PausedWorkflowModal";
import { StockEquityChart } from "screens/collection/components/StockEquityChart";
import { formatDateWithOutputFormat } from "screens/common/modal/formatters";

interface Props {
  collectionId: string;
  isChildProject?: boolean;
}

export const ProjectLandingDefaultTile: FunctionComponent<Props> = ({ collectionId, isChildProject }) => {
  const { projectFilter, isPortfolios, parentRoute } = useProjectParams();
  const subTitlecolor = useColorModeValue("charli.mediumGray", "gray.500");
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const navigate = useNavigate();
  const { onConversationOpen, onConversationClose } = useConversationContext();
  const configMap = useConfigMap();
  const collectionType = useCollectionKey(collectionId, "collectionType");
  const collectionMetadataIds = useCollectionKey(collectionId, "metadataIds");
  const collectionUnstructuredData = useCollectionKey(collectionId, "unstructuredData");
  const collectionMetadata = useCollectionKey(collectionId, "metadata");
  const collectionName = useCollectionKey(collectionId, "name");
  const projectGroupId = useCollectionKey(collectionId, "projectGroupId");
  const collectionConversationId = useCollectionKey(collectionId, "conversationId");
  const hasHighlights = useCollectionHasHighlights(collectionId);
  const projectRoute = useGetViewConfig("route", collectionType, configMap);
  const { searchText } = useContext(CollectionsFilterContext);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const commonTileProps = useTileProps();
  const collectionMetadataIdsOrEmpty = useMemo(() => collectionMetadataIds ?? [], [collectionMetadataIds]);
  const filteredContentIdsWithoutProjectOutput = useCollectionMetadataIds(collectionId);
  const showStockTicker = useGetViewConfig("showStockTicker", collectionType, configMap);
  const showProjectTags = useGetViewConfig("showProjectTags", collectionType, configMap);
  const tileRef = useRef<HTMLDivElement>(null);
  const leftSectionRef = useRef<HTMLDivElement>(null);
  const rightSectionRef = useRef<HTMLDivElement>(null);
  const [showWorkflowStepper, setShowWorkflowStepper] = useState(true);
  const workflowId = useMainCollectionWorkflowId(collectionId);
  const workflowStatus = useWorkflowStatus(workflowId);
  const portfoliosProjectIsIn = useProjectInPortfolios(collectionId);
  const { onDeleteProjectModalOpen } = useContext(ConversationContext);
  const { onOpen: onOpenMoveProject, onClose: onCloseMoveProject, isOpen: isOpenMoveProject } = useDisclosure();
  const { onOpen: onOpenPausedWorkflow } = usePausedWorkflowModal();
  const companyStockData = useCompanyStockFromCollectionContentsOrConversation(collectionId);
  const stockEquityData = useProjectStockEquityDataFromCollectionMetadata(collectionMetadataIdsOrEmpty || []);
  const projectCreationDate = useCollectionCreatedDate(collectionId);

  useEffect(() => {
    const tileElement = tileRef.current;
    const leftSectionElement = leftSectionRef.current;
    const rightSectionElement = rightSectionRef.current;

    if (tileElement && leftSectionElement && rightSectionElement) {
      const observer = new ResizeObserver(() => {
        const rightSectionWidth = 345;
        const doesContentFitInTile = tileElement.offsetWidth - 60 > leftSectionElement.offsetWidth + rightSectionWidth;
        setShowWorkflowStepper(doesContentFitInTile);
      });

      observer.observe(tileElement);
      observer.observe(leftSectionElement);
      observer.observe(rightSectionElement);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const hasRiskAssessment = useMemo(() => {
    return collectionUnstructuredData?.data?.assessment_results[0]?.risk_assessment_result || undefined;
  }, [collectionUnstructuredData]);

  const keyCollectionContent = useGetViewConfig("keyProjectContent", collectionType, configMap);

  const stockTitle = useMemo(() => {
    if (!companyStockData) return "-- : --";
    return `${companyStockData?.exchange}: ${companyStockData?.ticker}`;
  }, [companyStockData]);

  const renderProjectReportButton = useCallback(() => {
    if (keyCollectionContent && keyCollectionContent.includes("Project Report")) {
      return <ProjectReportPanelButton collectionId={collectionId} />;
    } else {
      return <React.Fragment />;
    }
  }, [collectionId, keyCollectionContent]);

  useDownloadCollectionContents(collectionMetadataIds, collectionId);

  const getPath = () => {
    if (projectRoute) {
      onConversationClose();
      return navigate(`/${parentRoute}/${projectFilter}/${collectionId}`);
    } else {
      onConversationOpen();
    }
  };

  const renderTags = useCallback(() => {
    return showProjectTags ? (
      <Box
        width={
          showStockTicker || hasRiskAssessment ? ["6rem", "10rem", "10rem", "14rem", "17rem"] : ["6rem", "14rem", "15rem", "17rem", "23rem"]
        }>
        <TagInput
          size="xs"
          tags={collectionMetadata?.autoTags || []}
          tagType="auto"
          hideInput
          allowUpdate={false}
          allowDelete={false}
          maxTagsToShow={3}
          searchPhrase={searchText}
        />
      </Box>
    ) : (
      <React.Fragment />
    );
  }, [collectionMetadata?.autoTags, hasRiskAssessment, searchText, showProjectTags, showStockTicker]);

  return (
    <>
      <Box
        ref={tileRef}
        {...commonTileProps}
        py="1rem"
        onClick={() => {
          getPath();
        }}
        overflow="visible"
        textAlign="left"
        className={`ch-collections-tab-collection-tile ch-project-landing-default-tile ${hasHighlights ? "has" : "no"}-highlights
      `}>
        <Stack direction="row" justifyContent="space-between" align="center" width="100%">
          <Stack ref={leftSectionRef} direction="row" align="center" spacing="1.2rem" justifyContent={"start"}>
            {!isMobile && (
              <Stack width="6rem" align="center">
                <CollectionStatProgress
                  {...(workflowStatus === "failed_checkstop" && {
                    onClick: () => {
                      onOpenPausedWorkflow(collectionId);
                    },
                  })}
                  showTimeDetails
                  collectionId={collectionId}
                  size="xxs"
                />
              </Stack>
            )}
            {collectionType && (
              <ProjectHeaderImage
                collectionId={collectionId}
                projectName={companyStockData?.name}
                metadataIds={filteredContentIdsWithoutProjectOutput}
                projectType={collectionType}
                projectGroupId={projectGroupId}
              />
            )}

            {showStockTicker ? (
              <>
                {!isMobile && (
                  <Stack className="ch-project-tile-stock-details" spacing="0" width={["6rem", "10rem", "10rem", "10rem", "11rem"]}>
                    <>
                      {searchText && searchText.length > 0 && companyStockData?.name ? (
                        <Text fontSize="sm" color={subTitlecolor} fontWeight="normal">
                          <ECHighlighter searchPhrase={searchText} text={companyStockData?.name || "--"} />
                        </Text>
                      ) : (
                        <TextOverflowTooltip label={companyStockData ? companyStockData.name : collectionName ?? "n/a"} />
                      )}
                      <TruncateMarkup>
                        <Text className="ch-project-stock-details-data" fontSize="sm" color={subTitlecolor} fontWeight="normal">
                          {`${companyStockData?.exchange || "--"} : ${companyStockData?.ticker || "--"}`}
                        </Text>
                      </TruncateMarkup>
                    </>
                  </Stack>
                )}
                {!isMobile && (
                  <Tooltip
                    zIndex={2}
                    placement="top"
                    label={`As of ${
                      projectCreationDate && formatDateWithOutputFormat(new Date(projectCreationDate), "do MMM yyyy hh:mm")
                    }`}>
                    <Box width="13rem">
                      {stockEquityData && <StockEquityChart width={200} height="2.4rem" hideAxis stockEquityData={stockEquityData} />}
                    </Box>
                  </Tooltip>
                )}
                {!isChildProject && (
                  <StockIndicator
                    companyTicker={companyStockData?.ticker}
                    companyStockTitle={stockTitle}
                    companyStockExchange={companyStockData?.exchange}
                  />
                )}
              </>
            ) : (
              <>
                {collectionName && (
                  <TextOverflowTooltip
                    className="ch-project-landing-default-tile-title"
                    label={collectionName}
                    style={{ width: showStockTicker || hasRiskAssessment || showProjectTags ? "10rem" : "20rem" }}
                  />
                )}
                {hasRiskAssessment && <RiskIndicator riskLevel={hasRiskAssessment} />}
              </>
            )}
            {!isMobile && renderTags()}
          </Stack>
          <Stack ref={rightSectionRef} direction="row" justifyContent="flex-end" align="center" spacing={"1.2rem"}>
            {showWorkflowStepper && (
              <Box maxWidth={["60vw", "26vw", "26vw"]} justifyContent="flex-end">
                <WorkflowProgressStepperContainer maxWidth="13rem" collectionId={collectionId} hideProgressBar={false} />
              </Box>
            )}
            <Stack direction="row" borderColor={tileBorderColor} borderWidth="1px" borderRadius="md" py="0.2rem" px=".5rem">
              {!isMobile && renderProjectReportButton()}
              <Menu isLazy>
                <Tooltip label="Project Options" aria-label="Options" placement="top">
                  <MenuButton
                    {...secondaryButtonProps}
                    className="ch-project-landing-tile-options-menu"
                    _hover={{}}
                    _active={{}}
                    variant="ghost"
                    as={IconButton}
                    aria-label="content options"
                    icon={<Icon as={IoEllipsisVertical} _active={{}} _hover={{}} />}
                    onClick={(event) => event.stopPropagation()}
                    minWidth="0"
                  />
                </Tooltip>
                <Portal>
                  <MenuList minWidth={"unset"} zIndex={10}>
                    {collectionId && (
                      <>
                        {(isPortfolios || projectFilter === "due_diligence") && (
                          <ProjectMoveButton isDisabled={workflowStatus === "in_progress"} onClick={onOpenMoveProject} />
                        )}
                        <ProjectRerunButton reRunProjectId={collectionId} />
                        <ProjectDeleteButton onOpenConfirmation={onDeleteProjectModalOpen} projectId={collectionId} isMenuItem />
                      </>
                    )}
                  </MenuList>
                </Portal>
              </Menu>
            </Stack>
          </Stack>
        </Stack>
        {!isPortfolios && !isMobile && (
          <Stack direction="row" align="center" justifyContent={"start"} position="absolute" bottom="-0.7rem" left="8.5rem">
            {portfoliosProjectIsIn.map((portfolio, index) => {
              return (
                <Tooltip key={portfolio.id} label={`Open portfolio ${portfolio.name}`} aria-label="Portfolio Name" placement="top">
                  <Tag
                    className="ch-project-landing-portfolio-tag"
                    zIndex={2}
                    variant="subtle"
                    borderColor={"gray.300"}
                    borderWidth="1px"
                    bgColor="white"
                    fontSize="0.65rem"
                    cursor={"pointer"}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      navigate(`/portfolios/${portfolio.id}`);
                    }}>
                    <Icon as={BiFolder} boxSize=".8rem" color={"gray.300"} mr="5px" />
                    {portfolio.name}
                  </Tag>
                </Tooltip>
              );
            })}
          </Stack>
        )}
      </Box>
      <MoveProjectModal
        isOpen={isOpenMoveProject}
        onClose={onCloseMoveProject}
        collectionId={collectionId}
        conversationId={collectionConversationId || uuid()}
      />
    </>
  );
};
