import { Text, useColorModeValue, Stack } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { SidebarButton } from "./SidebarButton";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import { BiInfoSquare } from "react-icons/bi";
import { useConversationContext } from "screens/thread/ConversationContext";

export const HelpMenu: React.FC = () => {
  const buttonColor = useColorModeValue("charli.darkBlue", "primaryButton.colorDarkMode");
  const subtitleColor = useColorModeValue("primary.lightGray", "primaryButton.colorDarkMode");
  const { showHelpPopups, setShowHelpPopups } = useConversationContext();

  const openWindow = useCallback((url: string) => () => window.open(url, "_blank"), []);

  const handleShowHintsClick = useCallback(() => {
    setTimeout(() => {
      setShowHelpPopups(!showHelpPopups);
    }, 300);
  }, [setShowHelpPopups, showHelpPopups]);

  const MenuItems = useMemo(
    () => [
      {
        text: `${showHelpPopups ? "Hide" : "Show"} Hints`,
        onClick: handleShowHintsClick,
        description: "Overlay hints on the screen to help you navigate and understand Charli.",
        color: buttonColor,
        disabled: false,
      },
      {
        text: "Submit Support Ticket",
        onClick: openWindow("https://support.charli.ai/hc/en-us/requests/new"),
        description: "Open a new support request.",
        color: buttonColor,
        disabled: false,
      },
      {
        text: "Explore help center",
        onClick: openWindow("https://support.charli.ai/"),
        description: "Explore our knowledge base and learn about Charli.",
        color: buttonColor,
        disabled: false,
      },
      {
        text: "Share an Idea",
        onClick: openWindow("https://portal.productboard.com/charli/1-product-portal/tabs/1-under-consideration/submit-idea"),
        description: "Share your feedback or create a feature request.",
        color: buttonColor,
        disabled: false,
      },
    ],
    [buttonColor, showHelpPopups, handleShowHintsClick, openWindow]
  );

  return (
    <Menu
      menuStyle={{ padding: "0" }}
      menuButton={
        <MenuButton>
          <SidebarButton text="Help" icon={BiInfoSquare} cssClasses={["ch-sidebar-nav-help"]} />
        </MenuButton>
      }>
      {MenuItems.map(({ text, onClick, description, color, disabled }, index) => (
        <MenuItem key={index} style={{ padding: "1rem" }} onClick={onClick} disabled={disabled}>
          <Stack direction="row" fontSize="sm" maxWidth="16rem">
            <Stack spacing=".3rem">
              <Text color={color}>{text}</Text>
              <Text fontSize="sm" color={subtitleColor}>
                {description}
              </Text>
            </Stack>
          </Stack>
        </MenuItem>
      ))}
    </Menu>
  );
};
