import { useCallback, useContext, useDeferredValue, useEffect, useMemo, useState } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCollectionKey, useConversation, useEntitlementKey, useProjectParams, useUserPreference } from "hooks";
import { v4 as uuid } from "uuid";
import type { SuggestedQuestions } from "api/suggestions";
import { useAvailableCommands, useCurrentCollectionQuestions } from "screens/collection/views/AutoCompleteInputUtils";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useDebouncedCallback } from "use-debounce";
import { useQASourceListFunction } from "hooks/useQASourceList";
import { useAnswerFocusOptions } from "hooks/useAnswerFocusOptions";
import { useDispatch } from "react-redux";
import { updateTypedUserPreference } from "state/userPreference/operations";

interface Props {
  questionText?: string;
  setQuestionText?: (questionText: string) => void;
  canBypassDisabled?: boolean;
}

export const restrictedCommands = ["create_due_diligence_project", "generate_answer"];

// TODO: refactoring input component for tickers, questions, and commands
// Current logic is a bit convoluted and could be simplified by breaking down into smaller components
export const useConversationDialogSuggestionLists = ({ questionText, setQuestionText, canBypassDisabled }: Props) => {
  const { projectId } = useProjectParams();
  const { conversationId, isConversationOpen } = useConversationContext();
  const projectConversationId = useCollectionKey(projectId, "conversationId") || uuid();
  const currentConversationID = projectConversationId || conversationId;
  const conversation = useConversation(currentConversationID);
  const { files } = useContext(InputBarDropzoneContext);
  const dispatch = useDispatch();
  const [currentSuggestionListType, setCurrentSuggestionListType] = useState<"commands" | "entities" | "questions" | "tickers" | undefined>(
    undefined
  );

  const [isLoading, setIsLoading] = useState(false);
  const maxSuggestions = 10;

  // COMMANDLINE SUGGESTIONS
  const hasCommandLineEntitlement = useEntitlementKey("ui_enable_command_suggestions");
  const hideCommandLineButtonUserPref = (useUserPreference("ui_hide_command_line_button") as boolean) || false;
  const { availableCommandsMemo: suggestedCommands, filterCommandEntitiesMemo: originalSuggestedEntities } = useAvailableCommands(
    questionText || "",
    hideCommandLineButtonUserPref || !hasCommandLineEntitlement
  );

  const [suggestedEntities, setSuggestedEntities] = useState<SuggestedQuestions>(originalSuggestedEntities);

  useEffect(() => {
    if (!questionText) {
      setSuggestedEntities(originalSuggestedEntities);
      return;
    }
    const segments = questionText.split(" ");
    const entitySegments = segments.filter((segment) => segment.startsWith(">")).map((segment) => segment.trim().toLowerCase());
    const lastSegment = entitySegments[entitySegments.length - 1] || "";
    const removeEndSegment = segments.slice(0, -1).join(" ");

    const updatedSuggestions = originalSuggestedEntities.reduce<SuggestedQuestions>((acc, entity) => {
      const entityLabel = (entity.label || entity.question).toLowerCase();

      if (!entitySegments.includes(entityLabel) && entityLabel.includes(lastSegment)) {
        acc.push({
          ...entity,
          question: `${removeEndSegment} ${entity.question}`.trim(),
          label: (entity.label || entity.question).trim(),
        });
      }

      return acc;
    }, []);
    setSuggestedEntities(updatedSuggestions);
  }, [questionText, originalSuggestedEntities]);

  // QUESTION SUGGESTIONS
  const getSourceQuestions = useQASourceListFunction("questions");
  const [sourcesList, setSourcesList] = useState<SuggestedQuestions>([]);
  useEffect(() => {
    setIsLoading(true);
    getSourceQuestions({ limit: maxSuggestions, ...(questionText && { questionText }) })
      .then((response) => setSourcesList(response))
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching question suggestions", error);
      })
      .finally(() => setIsLoading(false));
  }, [getSourceQuestions, maxSuggestions, questionText]);
  const { filterQuestionsByFocusMemo: suggestedQuestions } = useCurrentCollectionQuestions(sourcesList, projectId);

  // QUESTION FOCUS
  const { answerFocusOptions, selectedSourceWeightsIndex, setSelectedSourceWeightsIndex } = useAnswerFocusOptions("due_diligence");
  const selectedFocus = useMemo(() => {
    return answerFocusOptions ? answerFocusOptions[selectedSourceWeightsIndex]?.key : undefined;
  }, [answerFocusOptions, selectedSourceWeightsIndex]);

  const handleUpdateSourceWeightPreference = useCallback(
    (value: 0 | 1) => {
      dispatch(
        updateTypedUserPreference({
          preferenceKey: "source_weights_index",
          value: value,
        })
      );

      setSelectedSourceWeightsIndex(value);
    },
    [dispatch, setSelectedSourceWeightsIndex]
  );

  // TICKER SUGGESTIONS
  const getSourceTickers = useQASourceListFunction("tickers");
  const [tickersResultsMap, setTickersResultsMap] = useState<{ [key: string]: SuggestedQuestions }>({});
  const debouncedTickerFilter = useDebouncedCallback((query: string) => {
    if (tickersResultsMap[query]) {
      return;
    }
    setIsLoading(true);

    getSourceTickers({ limit: maxSuggestions, ...(query && { query }) })
      .then((response) => {
        setIsLoading(false);
        setTickersResultsMap((prev) => ({ ...prev, [query]: response }));
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching ticker suggestions", error);
      })
      .finally(() => setIsLoading(false));
  }, 150);
  const deferredQuestionText = useDeferredValue(questionText);

  useEffect(() => {
    if (currentSuggestionListType !== "tickers") return;

    if (deferredQuestionText && !deferredQuestionText.startsWith("/")) debouncedTickerFilter(deferredQuestionText);
  }, [debouncedTickerFilter, deferredQuestionText, currentSuggestionListType]);

  const suggestedTickers = useMemo(() => {
    return tickersResultsMap[questionText || ""] || [];
  }, [tickersResultsMap, questionText]);

  const isValidTicker: (query: string) => boolean = useCallback(
    (query: string) => {
      if (!tickersResultsMap[query]) {
        return true;
      } else {
        return tickersResultsMap[query].some((suggestedTicker) => suggestedTicker.matchFilter?.includes(query.trim().toLowerCase()));
      }
    },
    [tickersResultsMap]
  );

  // DETERMINE SUGGESTION LIST TO USE
  const currentSuggestionList = useMemo(() => {
    const determineListType = () => {
      if ((!canBypassDisabled && conversation.conversationState === "in_progress") || conversation.conversationState === "action") {
        return undefined;
      }

      if (((questionText && questionText.startsWith("/")) || files.length > 0) && isConversationOpen) {
        return questionText?.includes(" ") ? "entities" : "commands";
      } else {
        return projectId ? "questions" : "tickers";
      }
    };

    const listTypeMap = {
      disabled: [],
      entities: suggestedEntities,
      commands: suggestedCommands,
      questions: suggestedQuestions,
      tickers: suggestedTickers,
    };

    const listType = determineListType();

    setCurrentSuggestionListType(listType);

    return listType ? listTypeMap[listType] : [];
  }, [
    suggestedEntities,
    suggestedCommands,
    suggestedQuestions,
    suggestedTickers,
    canBypassDisabled,
    conversation.conversationState,
    questionText,
    files.length,
    isConversationOpen,
    projectId,
  ]);

  const isInputDisabled: boolean = useMemo(() => {
    switch (currentSuggestionListType) {
      case "questions":
        return questionText &&
          questionText.length > 0 &&
          !questionText.startsWith("/") &&
          conversation.conversationState !== "action" &&
          conversation.conversationState === "in_progress"
          ? true
          : false;
      default:
        return restrictedCommands.includes(conversation.latestUserRequest?.intent || "") && conversation.conversationState === "in_progress"
          ? true
          : false;
    }
  }, [currentSuggestionListType, questionText, conversation.conversationState, conversation.latestUserRequest?.intent]);

  const onResetQuestion = useCallback(() => {
    setQuestionText && setQuestionText("");
    setSelectedSourceWeightsIndex(0);
  }, [setQuestionText, setSelectedSourceWeightsIndex]);

  return {
    debouncedTickerFilter,
    isValidTicker,
    isLoading,
    currentSuggestionList,
    currentSuggestionListType,
    isInputDisabled,
    selectedFocus,
    handleUpdateSourceWeightPreference,
    answerFocusOptions,
    selectedSourceWeightsIndex,
    onResetQuestion,
    suggestedEntities,
    suggestedCommands,
    suggestedQuestions,
    suggestedTickers,
  };
};
