import React, { useRef, useEffect, useState, useCallback } from "react";
import * as d3 from "d3";
import { Box, Portal, Text, Icon, Flex } from "@chakra-ui/react";
import { calculateTimeDifference } from "screens/collection/components/utils";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { outputFormatWithSeconds } from "./WorkflowSummary";
import { formatDate } from "screens/common/modal/formatters";

export interface GanttTask {
  id: string;
  intent: string;
  name: string;
  creationDate: Date;
  start: Date;
  end: Date;
}

export interface GanttChartProps {
  tasks: GanttTask[];
  width: number;
  taskHeight?: number;
  taskPadding?: number;
}

const GanttChart: React.FC<GanttChartProps> = ({ tasks, width, taskHeight = 10, taskPadding = 0.1 }) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [popoverPosition, setPopoverPosition] = useState<{ top: number; left: number; task: GanttTask } | null>(null);
  const [zoom, setZoom] = useState<number>(1.0);
  const [mappedTasks, setMappedTasks] = useState<Record<string, GanttTask>>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverCallback = useCallback((ref: HTMLElement | null) => {
    if (!ref || !containerRef.current) {
      return;
    }

    const containerWidth = containerRef.current.clientWidth;
    const containerHeight = containerRef.current.clientHeight;

    const poppoverWidth = ref.clientWidth;
    const poppoverHeight = ref.clientHeight;

    setPopoverPosition((prev) => {
      if (prev) {
        return {
          ...prev,
          top: prev.top + poppoverHeight > containerHeight ? prev.top - poppoverHeight : prev.top || 10,
          left: prev.left + poppoverWidth > containerWidth ? prev.left - poppoverWidth : prev.left || 10,
        };
      }

      return prev;
    });
  }, []);

  const handleZoomInOut = (zoomIn: boolean) => {
    if (zoomIn) {
      setZoom(zoom + 0.1);
    } else {
      if (zoom <= 0.5) return;
      setZoom(zoom - 0.1);
    }
  };

  // Calculate the height based on the number of tasks
  const height = tasks.length * (taskHeight + taskHeight * taskPadding);

  useEffect(() => {
    const mappedTasks: Record<string, GanttTask> = {};
    tasks.forEach((task) => {
      mappedTasks[task.id] = task;
    });

    setMappedTasks(mappedTasks);
  }, [tasks]);

  useEffect(() => {
    if (!svgRef.current) return;

    // Set up SVG canvas
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Clear previous renders

    const margin = { top: 20, right: 20, bottom: 40, left: 200 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Create scales
    const xScale = d3
      .scaleTime()
      .domain([d3.min(tasks, (d) => d.start)!, d3.max(tasks, (d) => d.end)!])
      .range([0, innerWidth]);

    const yScale = d3
      .scaleBand()
      .domain(tasks.map((task) => task.id))
      .range([0, innerHeight])
      .padding(taskPadding);

    // Create axes with finer time granularity
    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat(d3.timeFormat("%H:%M:%S.%L") as unknown as (value: Date | { valueOf(): number }) => string);

    const yAxis = d3.axisLeft(yScale).tickFormat((id) => {
      const task = mappedTasks[id];
      return task ? task.intent : id;
    });

    // Append group for the chart
    const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

    // Draw bars
    g.selectAll(".bar")
      .data(tasks)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => xScale(d.start)!)
      .attr("y", (d) => yScale(d.id)!)
      .attr("width", (d) => xScale(d.end)! - xScale(d.start)!)
      .attr("height", yScale.bandwidth())
      .attr("fill", "steelblue")
      .on("mouseover", function (event, d) {
        setPopoverPosition({
          top: event.clientY,
          left: event.clientX,
          task: d,
        });

        d3.select(this).attr("fill", "orange"); // Change the bar color on hover
      })
      .on("mouseout", function (event, d) {
        setPopoverPosition(null);
        d3.select(this).attr("fill", "steelblue"); // Reset the bar color on mouse out
      });

    // Append axes
    g.append("g").attr("transform", `translate(0,${innerHeight})`).call(xAxis);

    g.append("g").call(yAxis);
  }, [tasks, mappedTasks, width, height, taskHeight, taskPadding]);

  return (
    <Box>
      <Box ref={containerRef} overflow={"auto"} width="100%" height={"calc(100vh - 150px)"}>
        <svg style={{ zoom }} ref={svgRef} width={width} height={height} />
      </Box>
      <Flex justifyContent={"flex-end"} align={"center"} p="1rem">
        <Icon _hover={{ cursor: "pointer" }} onClick={() => handleZoomInOut(false)} as={FiZoomOut} />
        <Text ml="1rem" mr="1rem">
          {Math.round(zoom * 100)}%
        </Text>
        <Icon _hover={{ cursor: "pointer" }} onClick={() => handleZoomInOut(true)} as={FiZoomIn} />
      </Flex>
      {popoverPosition && (
        <Portal appendToParentPortal={false}>
          <Box
            ref={popoverCallback}
            boxShadow="md"
            p="6"
            rounded="md"
            bg="white"
            zIndex={10000}
            borderRadius={"5px"}
            position={"absolute"}
            top={popoverPosition.top}
            left={popoverPosition.left}>
            <Text fontSize={"xs"}>Intent: {popoverPosition.task.intent}</Text>
            <Text fontSize={"xs"}>Task Name: {popoverPosition.task.name}</Text>
            <Text fontSize={"xs"}>Creation Date: {formatDate(new Date(popoverPosition.task.creationDate), outputFormatWithSeconds)}</Text>
            <Text fontSize={"xs"}>Start Date: {formatDate(new Date(popoverPosition.task.start), outputFormatWithSeconds)}</Text>
            <Text fontSize={"xs"}>End Date: {formatDate(new Date(popoverPosition.task.end), outputFormatWithSeconds)}</Text>
            <Text fontSize={"xs"}>Duration: {calculateTimeDifference(popoverPosition.task.start, popoverPosition.task.end)}</Text>
          </Box>
        </Portal>
      )}
    </Box>
  );
};

export default GanttChart;
