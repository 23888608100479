import { Stack, Text, Center, Box, Icon } from "@chakra-ui/react";
import React from "react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useWizard } from "react-use-wizard";
import traderBanner from "screens/common/static/misc/trader_banner.jpg";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";
import { ONBOARDING_MODEL_HEIGHT } from "./OnboardingModal";

export const OnboardingWelcomeStep = () => {
  const { nextStep } = useWizard();

  return (
    <Stack justifyContent="space-between" spacing="0" bgColor="#4799d4" height={ONBOARDING_MODEL_HEIGHT}>
      <Box backgroundImage={`url(${traderBanner})`} backgroundSize="cover" backgroundPosition="center" borderTopRadius="md">
        <Box opacity={0.6} height="10rem" bgColor="#4799d4" />
        <Center position="absolute" top="2.3rem" width="100%">
          <Text color="white" fontSize="2.3rem" lineHeight={"2.5rem"} fontWeight={"bold"} textAlign={"center"} width="22rem">
            Welcome to The Automated Analyst
          </Text>
        </Center>
      </Box>
      <Stack spacing="1rem" pt="2rem" justifyContent={"space-between"} height="100%" width="100%" color="white" bgColor="#4799d4">
        <Center>
          <Stack spacing="4rem">
            <Stack maxWidth="21rem" spacing={"1rem"} pt="2rem">
              <Stack alignItems="center" direction={"row"} spacing="1rem">
                <Icon as={IoMdCheckmarkCircleOutline} boxSize="1.1rem" />
                <Text textAlign="left">Analyze up to 3 different equities</Text>
              </Stack>
              <Stack direction={"row"} spacing="1rem">
                <Icon as={IoMdCheckmarkCircleOutline} boxSize="1.1rem" />
                <Text textAlign="left">Get automated reports delivered right to your inbox</Text>
              </Stack>
              <Stack direction={"row"} spacing="1rem">
                <Icon as={IoMdCheckmarkCircleOutline} boxSize="1.1rem" />
                <Text textAlign="left">Ask up to 15 questions to dive deeper into the analysis and discover new insights</Text>
              </Stack>
            </Stack>
            <Stack spacing="1rem" width="100%">
              <Center>
                <Text fontSize="lg" fontWeight="semibold" textAlign="left">
                  Unlock $225 in value
                </Text>
              </Center>
              <Center>
                <UpgradePlanButton
                  label="Get Started"
                  style={{ backgroundColor: "#efb943", fontSize: "1rem", borderRadius: "3rem", padding: "1.2rem 2rem" }}
                  buttonSize="sm"
                  onClick={nextStep}
                />
              </Center>
            </Stack>
          </Stack>
        </Center>
      </Stack>
    </Stack>
  );
};
