import React from "react";
import { Button, Text, Stack, Center, Box, ModalCloseButton } from "@chakra-ui/react";
import { useConversationContext } from "screens/thread/ConversationContext";
import traderBanner from "screens/common/static/misc/trader_banner.jpg";
import { UpgradePlanButton } from "screens/panels/settings/tabs/subscription/SubscriptionUpgrade";
import { CommonModal } from "./CommonModal";

const ModalBody = () => {
  const { onSubscriptionModalOpen, onUpgradeModalClose } = useConversationContext();

  return (
    <Stack justifyContent="space-between" spacing="0" bgColor="#4799d4">
      <Box backgroundImage={`url(${traderBanner})`} backgroundSize="cover" backgroundPosition="center" borderTopRadius="md">
        <Box opacity={0.6} height="10rem" bgColor="#4799d4" />
        <Center position="absolute" top="2.3rem" width="100%">
          <Text color="white" fontSize="2.3rem" lineHeight={"5rem"} fontWeight={"bold"} textAlign={"center"} width="26rem">
            The Automated Analyst
          </Text>
        </Center>
      </Box>
      <ModalCloseButton cursor="pointer" color="white" _hover={{}} />;
      <Stack spacing="1.5rem" px="1.5rem" py="1rem">
        <Stack textAlign="center" color="white" px="2rem" spacing="1.5rem" bgColor="#4799d4">
          <Text fontSize="xl" fontWeight="normal">
            Upgrade to Business to analyze your next equity!
          </Text>
          <Text fontSize="md" fontWeight="normal">
            Allow AI to continue to put the market and data research on Autopilot so you can spend more time analyzing.
          </Text>
          <Text fontSize="md" fontWeight="normal">
            Avoid the endless hours sitting in front of a prompt and get AI to do the work while you commute, meet, call or even sleep.
          </Text>
          <Button
            color="white"
            fontWeight="hairline"
            fontSize="md"
            onClick={() => window.open("https://portal.productboard.com/charli/1-product-portal/tabs/2-planned", "_blank")}
            variant="link">
            Click here to learn about new features coming soon!
          </Button>
        </Stack>
        <Stack spacing="1rem" py="1rem" width="100%">
          <Center>
            <UpgradePlanButton
              style={{ backgroundColor: "#efb943", fontSize: "1rem", borderRadius: "3rem", padding: "1.2rem" }}
              buttonSize="sm"
              onClick={() => {
                onSubscriptionModalOpen();
                onUpgradeModalClose();
              }}
            />
          </Center>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const UpgradeModalTrialNotice = () => {
  const { isUpgradeModalOpen, onUpgradeModalClose } = useConversationContext();

  return <CommonModal isOpen={isUpgradeModalOpen} onClose={onUpgradeModalClose} modalBody={<ModalBody />} borderColor="#4799d4" />;
};
