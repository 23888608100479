import {
  Button,
  Center,
  ListItem,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
  Image,
  Icon,
  Tbody,
  Td,
  Tr,
  Table,
  HStack,
  useBreakpointValue,
  Box,
  ModalCloseButton,
} from "@chakra-ui/react";
import type { PlanName } from "api/subscription/models/PlanName";
import { useButtonProps, useUserProfile, useUserSubscriptionDetails, useUserSubscriptionDetailsDaysTillNextBilling } from "hooks";
import React from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import factsetLogo from "screens/common/static/logos/factset_logo.svg";
import nasdaqLogo from "screens/common/static/logos/nasdaq_logo.svg";
import tmxLogo from "screens/common/static/logos/tmx_logo.svg";
import reportImage from "screens/common/static/misc/reports_panel_image.png";
import verifiedShield from "screens/common/static/misc/verified_shield.svg";
import soc2logo from "screens/common/static/logos/soc2_logo.png";
import { FaCheck } from "react-icons/fa";
import stockChartImage from "screens/common/static/misc/stock_chart.jpg";
import { useWizard } from "react-use-wizard";
import { track } from "api/analytics";
import { USER_UPGRADE_PLAN_CLICKED, USER_UPGRADE_PLAN_CONTINUE } from "api/analytics/events";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { useLocation } from "react-router-dom";

export interface Feature {
  name: string;
  trial: boolean | string;
  business: boolean | string;
}

export const subscriptionFeatures = () => {
  return [
    {
      name: "Supercharge analysis with FactSet trusted data including street accounts, fundamentals, M&A, transcripts and so much more",
      trial: true,
      business: true,
    },
    { name: "Automatically fetch, read, and analyze complex EDGAR and SEDAR global filing information", trial: true, business: true },
    { name: "Augment research with sentiment analysis from verified news and internet content", trial: true, business: true },
    {
      name: "Full Autopilot analysis from ticker to report with a comprehensive editable report delivered right to your desk",
      trial: true,
      business: true,
    },
    { name: "Number of Analysis (per ticker)", trial: "3", business: "20 per month" },
    { name: "Number of Private Q&A questions", trial: "15", business: "200 per month" },
    { name: "Data Archiving and Record Keeping", trial: "30 Days", business: "180 Days" },
  ];
};

export const SubscriptionUpgrade = ({ upgradePlan = "business" }: { upgradePlan: PlanName }) => {
  const buttonStyle = useButtonProps("lg", "subscribe");
  const features = subscriptionFeatures();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const { nextStep } = useWizard();
  const { email, fullName } = useUserProfile();
  const subscriptionInfo = useUserSubscriptionDetails();
  const daysTillPlanExpiry = useUserSubscriptionDetailsDaysTillNextBilling();
  const answerUsage = useFeatureUsage(Intent.generateAnswer);
  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const { pathname } = useLocation();

  const handleOnClick = () => {
    track(USER_UPGRADE_PLAN_CONTINUE, {
      pageViewed: pathname,
      userName: fullName,
      userEmail: email,
      answersUsed: answerUsage?.used,
      dueDiligenceUsed: projectUsage?.used,
      currentPlan: subscriptionInfo.plan,
      planStatus: subscriptionInfo.status,
      nextBillingDate: subscriptionInfo.nextBillingDate,
      subscriptionId: subscriptionInfo.subscriptionId,
      daysTillPlanExpiry: daysTillPlanExpiry.daysTillNextBilling,
    });
    nextStep();
  };

  return (
    <Stack direction={isMobile ? "column" : "row"} height={"47.5rem"}>
      <ModalCloseButton />
      {!isMobile && (
        <Stack
          width="30rem"
          overflowY="auto"
          backgroundImage={`url(${stockChartImage})`}
          backgroundSize="cover"
          backgroundPosition="center">
          <Box opacity={0.8} height="100%" bgColor="#4799d4" width="100%" />
          <Stack
            width="19rem"
            position={"absolute"}
            height={"100%"}
            color="white"
            borderLeftRadius={"md"}
            p="2rem"
            justifyContent={"space-between"}>
            <Stack spacing="4rem" pt="1rem">
              <Text fontSize="1.5rem" lineHeight={"1.7rem"} fontWeight="bold" width="100%" textAlign={"center"}>
                The Automated Analyst
              </Text>
              <Center>
                <UnorderedList spacing={"1rem"}>
                  <ListItem>Trusted and verified AI results with data direct from FactSet</ListItem>
                  <ListItem>Deeper insights with Multidimensional AI for better analysis and decisions</ListItem>
                </UnorderedList>
              </Center>
            </Stack>
            <Center>
              <HStack align={"center"} spacing="1rem">
                <Image src={factsetLogo} height="1rem" />
                <Image src={nasdaqLogo} height="1.5rem" />
                <Image src={tmxLogo} height="2rem" />
              </HStack>
            </Center>
          </Stack>
        </Stack>
      )}
      <Stack justifyContent={"space-between"} height="100%" spacing="2rem" p="2rem" width="100%">
        <Stack spacing="3rem">
          <HStack align={"center"} spacing="1rem">
            <Text color="gray.800" fontWeight={"semibold"} fontSize="1.3rem" lineHeight={"1.5rem"}>
              Upgrade to Business to continue receiving the benefits of Autopilot
            </Text>
            <Image src={reportImage} height="5rem" />
          </HStack>
          <Table variant="simple" size="sm">
            <Tbody>
              {features.map((feature, index) => (
                <Tr fontSize={"sm"} key={index}>
                  <Td paddingInline={"0"}>{feature.name}</Td>
                  <Td textAlign={"end"} width="8rem" color="green.600">
                    {feature.business === true ? <Icon as={FaCheck} /> : feature.business}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Stack>
        <Stack>
          <Center pt="2rem">
            <Button {...buttonStyle} width="16rem" borderRadius="full" onClick={handleOnClick}>
              Continue with Upgrade
            </Button>
          </Center>
        </Stack>
        {!isMobile && (
          <HStack position="absolute" bottom="2rem" right="2rem" align={"center"} spacing="1rem">
            <Image src={soc2logo} height="2.7rem" />
            <Image src={verifiedShield} height="2.5rem" />
          </HStack>
        )}
      </Stack>
    </Stack>
  );
};

export const UpgradePlanButton = ({
  buttonSize,
  label,
  tooltip,
  style,
  isDisabled,
  onClick,
}: {
  buttonSize: string;
  label?: string;
  tooltip?: string;
  style?: any;
  isDisabled?: boolean;
  onClick?: () => void;
}) => {
  const buttonStyle = useButtonProps(buttonSize, "subscribe");
  const { isUpgrading, onSubscriptionModalOpen } = useConversationContext();
  const { email, fullName } = useUserProfile();
  const subscriptionInfo = useUserSubscriptionDetails();
  const daysTillPlanExpiry = useUserSubscriptionDetailsDaysTillNextBilling();
  const answerUsage = useFeatureUsage(Intent.generateAnswer);
  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const { pathname } = useLocation();

  const handleOnClick = () => {
    if (isDisabled || isUpgrading) {
      return;
    }

    track(USER_UPGRADE_PLAN_CLICKED, {
      pageViewed: pathname,
      userName: fullName,
      userEmail: email,
      answersUsed: answerUsage?.used,
      dueDiligenceUsed: projectUsage?.used,
      currentPlan: subscriptionInfo.plan,
      planStatus: subscriptionInfo.status,
      nextBillingDate: subscriptionInfo.nextBillingDate,
      subscriptionId: subscriptionInfo.subscriptionId,
      daysTillPlanExpiry: daysTillPlanExpiry.daysTillNextBilling,
    });

    if (onClick) {
      onClick();
    } else {
      onSubscriptionModalOpen();
    }
  };

  return (
    <Tooltip label={tooltip} aria-label={tooltip} maxWidth="13rem">
      <Button isDisabled={isDisabled || isUpgrading} {...buttonStyle} {...style} onClick={handleOnClick}>
        {label ? label : `Upgrade to Business`}
      </Button>
    </Tooltip>
  );
};
