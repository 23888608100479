import { useCollectionKey, useConfigMap, useGetViewConfig, useLatestCollectionWorkflowId, useProjectParams } from "hooks";
import { useWorkflowCompletionDateOrNow, useWorkflowKey } from "hooks/useWorkflows";
import { useMaxAllowedProjectsReached } from "hooks/useStats";
import { useState } from "react";

export const useConversationDialogUtils = () => {
  const { projectFilter, projectId, parentRoute } = useProjectParams();
  const maybeCollectionType = useCollectionKey(projectFilter, "collectionType");
  const configMap = useConfigMap();
  const portfolioCollectionType = useGetViewConfig("portfolioProjectType", maybeCollectionType, configMap);
  const currentCollectionType =
    portfolioCollectionType || maybeCollectionType || projectFilter || parentRoute === "home" ? "due_diligence" : undefined;
  const hasExceededMaxProjects = useMaxAllowedProjectsReached(currentCollectionType || "");
  const maxAllowedProjects = useGetViewConfig("maxActiveProjectsAllowed", currentCollectionType || "", configMap);
  const currentWorkflowId = useLatestCollectionWorkflowId(projectId);
  const workflowStatus = useWorkflowKey(currentWorkflowId, "status");
  const currentWorkflowCompletionDate = useWorkflowCompletionDateOrNow(currentWorkflowId);
  const [canBypassDisabled, setCanBypassDisabled] = useState(false);

  return {
    hasExceededMaxProjects,
    currentCollectionType,
    maxAllowedProjects,
    currentWorkflowId,
    workflowStatus,
    currentWorkflowCompletionDate,
    canBypassDisabled,
    setCanBypassDisabled,
  };
};
