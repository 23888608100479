import type { Static } from "runtypes";
import { Array, Boolean, Literal, Null, Number, Record, String, Union, Unknown } from "runtypes";

export const State = Union(Literal("active"), Literal("draft"), Literal("backup"), Literal("deleted"));

const Entity = Record({
  entity: String,
  value: Unknown,
});

const Requirement = Record({
  entity: String,
  operator: Union(
    Literal("equal"),
    Literal("not_equal"),
    Literal("not_in"),
    Literal("in"),
    Literal("contains"),
    Literal("does_not_contain")
  ),
  value: Union(String, Array(String), Number, Null, Boolean),
});

const CreateEntitiesCheckpoint = Record({
  id: String.optional(),
  type: Literal("create_entities"),
  entitiesToInject: Array(Entity),
  requirements: Array(Requirement).optional(),
});

const ExecuteIntentCheckpoint = Record({
  // TODO: optional should be removed once all the data is migrated
  id: String.optional(),
  type: Literal("execute_intent").optional(),
  intent: String,
  needsConfirmation: Boolean.optional(),
  runInNewChildWorkflow: Boolean.optional(),
  onlyIncludeTheseEntities: Array(String).optional(),
  entityToSplit: Record({
    entityName: String,
    renameTo: String.optional(),
    shouldUnwrap: Boolean,
  }).optional(),
  requirements: Array(Requirement),
  entitiesToRename: Array(Record({ entityName: String, renameTo: String })).optional(),
  entitiesToInject: Array(Entity).optional(),
});

const Checkpoint = Union(CreateEntitiesCheckpoint, ExecuteIntentCheckpoint);

const Config = Record({
  defaultEntities: Array(Entity).optional(),
  checkpoints: Array(Checkpoint),
});

export const WorkflowConfigData = Record({
  userIntent: String,
  config: Config,
  notes: String.optional(),
});

export const ConfiguredWorkflow = WorkflowConfigData.extend({
  id: String,
  creationByUserId: String,
  creationDate: String,
  lastUpdatedByUserId: String,
  lastUpdatedDate: String,
  creationByUserName: String,
  lastUpdatedByUserName: String,
  deletionByUserId: String.nullable(),
  deletionDate: String.nullable(),
  state: State,
  onlyAvailableToUserIds: Array(String).nullable(),
  approvedByUsers: Array(Record({ id: String, name: String })).optional(),
  version: String,
  canBePublished: Boolean,
  canBeEdited: Boolean,
});

export type ConfiguredWorkflow = Static<typeof ConfiguredWorkflow>;
export type WorkflowConfigData = Static<typeof WorkflowConfigData>;
