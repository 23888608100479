import React, { useCallback, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { updateUser as updateUserThunk } from "state/session/operations";
import { deleteUser as deleteUserMethod } from "api/user";
import type { RootState } from "state/rootReducer";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/toast";
import { captureException } from "@sentry/browser";
import { ToastMessageContent } from "screens/common/components";
import type { SubscriptionDetails } from "api/subscription/models/SubscriptionDetails";

const getFullName = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName && !lastName) {
    return firstName;
  }
};

export function useUserSubscriptionDetails(): SubscriptionDetails {
  const userSubscription = useSelector((state: RootState) => state.subscriptions.subscriptionDetails);
  return userSubscription;
}

export function useUserSubscriptionDetailsDaysTillNextBilling() {
  const expireWarningDays = 4;
  const userSubscription = useSelector((state: RootState) => state.subscriptions.subscriptionDetails);
  const todaysDate = new Date();
  const nextBillingDate = new Date(userSubscription.nextBillingDate ? userSubscription.nextBillingDate : 0);
  const daysTillNextBilling = Math.floor((nextBillingDate.getTime() - todaysDate.getTime()) / (1000 * 60 * 60 * 24));
  return { daysTillNextBilling, todaysDate, nextBillingDate, expireWarningDays };
}

/**
 * Returns a user's profile data.
 */
export function useUserProfile() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.session.user, shallowEqual);
  const isProfileUpdatePending = useSelector((state: RootState) => state.session.isProfileUpdatePending);
  const [isDeletePending, setIsDeletePending] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  const updateUser = useCallback(
    ({ firstName, lastName }: { firstName: string; lastName: string }) => {
      dispatch(updateUserThunk({ firstName, lastName }));
    },
    [dispatch]
  );

  const deleteUser = useCallback(
    async ({ dataOnly }: { dataOnly: boolean }) => {
      if (!dataOnly) {
        // If the user has opted to delete their account, log them out immediately.
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={"Sorry to see you go! Your account will be deleted within the next 15 minutes. Feel free to come back any time!"}
              onClose={onClose}
            />
          ),
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setTimeout(() => {
          navigate("/logout");
        }, 3000);
      } else {
        // If the user is keeping their account, just warn them that it may take up to 15 minutes to finish
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={
                "The request to delete your account's data is in progress. This may take up to 5 minutes to complete. You will be logged out now to clear your session data."
              }
              onClose={onClose}
            />
          ),
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setTimeout(() => {
          navigate("/logout");
        }, 3000);
      }
      try {
        setIsDeletePending(true);
        await deleteUserMethod({ dataOnly });
        setTimeout(() => {
          navigate("/logout");
        }, 3100);
      } catch (err) {
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={"An error occurred when deleting your user data. Please reach out to support@charli.ai for further assistance."}
              onClose={onClose}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        console.error("Error when deleting user data:", err);
        captureException(err);
      } finally {
        setIsDeletePending(false);
      }
    },
    [navigate, toast]
  );

  // Since user could be null, return each property of user as an optional to make the hook more ergonomic (eg. allowing the user object to be destructured)
  return {
    id: user?.id,
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    fullName: getFullName(user?.firstName, user?.lastName),
    avatarUrl: user?.avatarUrl,
    identityProvider: user?.identityProvider,
    creationDate: user?.creationDate,
    organizations: user?.organizations,
    isProfileUpdatePending,
    isDeletePending,
    analyticsId: user?.analyticsId,
    updateUser,
    deleteUser,
  };
}
