import { Button, Text, Stack, Center, ModalCloseButton, useBreakpointValue } from "@chakra-ui/react";
import { getViewConfig } from "configs/configMap";
import { useButtonProps, useConfigMap, usePortfolioCollections } from "hooks";
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import { getRequestValue, updateBatchRequestEntities } from "screens/conversation/components/RequestEntitiesUtils";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";
import { useSendToCharli } from "screens/panels/addToCharli/AddToCharliWizard/useSendToCharli";
import { ProjectCriteriaForm } from "screens/panels/research/ProjectCriteriaForm";
import { ResearchContext } from "screens/panels/research/ResearchContext";
import { ConversationContext, useConversationContext } from "screens/thread/ConversationContext";
import { v4 as uuid } from "uuid";
import startCase from "lodash/startCase";
import { CommonModal } from "./CommonModal";
import equity from "screens/common/static/misc/portfolio_equity.jpg";
import market from "screens/common/static/misc/portfolio_market.jpg";
import mergers from "screens/common/static/misc/portfolio_mergers.jpg";
import environment from "screens/common/static/misc/portfolio_environment.jpg";
import eft from "screens/common/static/misc/portfolio_eft.jpg";
import dividends from "screens/common/static/misc/portfolio_dividends.jpg";
import { TileSelector } from "./TileSelector";

export const portfolioSelectors = [
  {
    label: "Equities Research",
    entityName: "portfolio_theme",
    value: "equity_research",
    image: equity,
    imageDimensions: { width: "9rem", height: "5rem" },
    default: true,
  },
  {
    label: "Mergers & Acquisitions",
    entityName: "portfolio_theme",
    value: "mergers_and_acquisitions",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: mergers,
  },
  {
    label: "Market Sector Performance",
    entityName: "portfolio_theme",
    value: "market_sector_performance",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: market,
  },
  {
    label: "Environmental Due Diligence",
    entityName: "portfolio_theme",
    value: "environmental_due_diligence",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: environment,
  },
  {
    label: "Dividend Analysis",
    entityName: "portfolio_theme",
    value: "dividend_analysis",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: dividends,
  },
  {
    label: "Asset Bundles and ETFs",
    entityName: "portfolio_theme",
    value: "asset_bundles_and_etf",
    imageDimensions: { width: "9rem", height: "5rem" },
    image: eft,
  },
];

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onClickStep?: () => void;
  modalColor?: string;
  modalFontColor?: string;
  modalTitle?: string;
  closeButton?: boolean;
}

export const ModalHeader: React.FC<Props> = ({ modalColor = "#4799d4", modalFontColor = "white", modalTitle, closeButton }) => {
  return (
    <Stack
      color={modalFontColor}
      bgColor={modalColor}
      direction={"row"}
      fontSize="md"
      textAlign="center"
      width="100%"
      height={"3rem"}
      justifyContent={"space-between"}
      px="1rem">
      <Center>
        <Text fontWeight={"semibold"} fontSize="lg">
          {modalTitle}
        </Text>
      </Center>
      {closeButton && (
        <Center>
          <ModalCloseButton cursor="pointer" _hover={{}} />
        </Center>
      )}
    </Stack>
  );
};

export const NewPortfolioModalBody: React.FC<Props> = ({ isOpen, onClose, onClickStep }) => {
  const { selectedProjectCategory, setSelectedProjectCategory } = useConversationContext();
  const commonButtonProps = useButtonProps("sm", "cta");
  const { setProjectType, setIsAddToResearch } = useContext(ResearchContext);
  const { requestEntities, setRequestEntities, setConversationId } = useContext(ConversationContext);
  const { resetFormState } = useAddToCharliContext();
  const configMap = useConfigMap();
  const { sendRequest } = useSendToCharli();
  const selectedCategoryFormatted = selectedProjectCategory?.replace(/_/g, " ") || "";
  const portfolioCollections = usePortfolioCollections();
  const [portfolioName, setPortfolioName] = useState<string>("");
  const [isPortfolioNameUsed, setIsPortfolioNameUsed] = useState(false);
  const [isCreatingPortfolio, setIsCreatingPortfolio] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  useEffect(() => {
    updateBatchRequestEntities(
      [
        {
          entity: "collection_type",
          value: selectedProjectCategory || "portfolio",
          source: "portfolio-selector",
        },
      ],
      setRequestEntities
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedProjectCategory]);

  useEffect(() => {
    const portfolioNameEntered = getRequestValue("collection_name", requestEntities);
    setPortfolioName(portfolioNameEntered);
    setIsPortfolioNameUsed(portfolioCollections.some((collection) => collection.name === portfolioNameEntered));
    return () => {
      setIsPortfolioNameUsed(false);
    };
  }, [requestEntities, portfolioCollections]);

  const handleOnClose = useCallback(() => {
    setIsAddToResearch(false);
    setRequestEntities([]);
    resetFormState();
    setProjectType(undefined);
    onClose && onClose();
    setSelectedProjectCategory(undefined);
  }, [setIsAddToResearch, setRequestEntities, resetFormState, setProjectType, onClose, setSelectedProjectCategory]);

  const onHandleSubmit = useCallback(() => {
    const newConversationId = uuid();
    setConversationId(newConversationId);
    const intent = `${getViewConfig("intent", selectedProjectCategory || "portfolio", configMap)}`;
    setIsCreatingPortfolio(true);

    sendRequest(intent, handleOnClose, `I'll set up your ${portfolioName} portfolio now. It will be ready for you in a few seconds.`);
    onClickStep && onClickStep();
  }, [setConversationId, selectedProjectCategory, configMap, sendRequest, handleOnClose, portfolioName, onClickStep]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!isOpen) return;
      if (event.key === "Enter" && portfolioName.length > 0) {
        onHandleSubmit();
      }
      if (event.key === "Escape") {
        handleOnClose();
      }
    },
    [handleOnClose, isOpen, onHandleSubmit, portfolioName.length]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const firstInput = modalRef.current?.querySelector("input");
        if (firstInput) {
          (firstInput as HTMLInputElement).focus();
        }
      }, 0);
      updatePortfolioEntity(portfolioSelectors[0].entityName, portfolioSelectors[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const updatePortfolioEntity = useCallback(
    (entityName: string, entityValue: string) => {
      updateBatchRequestEntities([{ entity: entityName, value: entityValue, source: "portfolio-selector" }], setRequestEntities);
    },
    [setRequestEntities]
  );

  return (
    <Stack ref={modalRef} justifyContent="space-between" spacing="0" height="32.5rem">
      <Stack
        spacing="2rem"
        px={isMobile ? "1rem" : "2rem"}
        pt=".5rem"
        pb="1.5rem"
        width="100%"
        height={"100%"}
        justifyContent="space-between">
        <Stack spacing="2rem">
          <Stack spacing=".5rem">
            <ProjectCriteriaForm collectionType={selectedProjectCategory || "portfolio"} />
            {isPortfolioNameUsed && (
              <Text as="i" fontSize="sm" color="gray.500">
                {`"${portfolioName}" is already in use. Please choose a different name.`}
              </Text>
            )}
          </Stack>
          <TileSelector
            layout="scroll"
            title="Portfolio Theme"
            tiles={portfolioSelectors}
            onSelectedTile={(tile) => updatePortfolioEntity(tile.entityName, tile.value)}
          />
        </Stack>
        <Stack direction="row" justifyContent={"end"} width="100%">
          <Button
            {...commonButtonProps}
            isDisabled={isPortfolioNameUsed || portfolioName.length === 0 || isCreatingPortfolio}
            id={`ch-create-${selectedProjectCategory}`}
            aria-label="submit"
            onClick={onHandleSubmit}>
            {`Create ${startCase(selectedCategoryFormatted || "portfolio")}`}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const NewPortfolioModal = () => {
  const { isPortfolioModalOpen, onPortfolioModalClose } = useConversationContext();

  return (
    <CommonModal
      borderColor="#4799d4"
      isOpen={isPortfolioModalOpen}
      onClose={onPortfolioModalClose}
      modalHeader={<ModalHeader modalColor="#4799d4" modalFontColor="white" modalTitle="Create New Portfolio" closeButton />}
      modalBody={<NewPortfolioModalBody isOpen={isPortfolioModalOpen} onClose={onPortfolioModalClose} />}
    />
  );
};
