import { Box, Center, Icon, Stack, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { useUserSubscriptionDetails } from "hooks";
import capitalize from "lodash/capitalize";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { subscriptionFeatures, UpgradePlanButton } from "./SubscriptionUpgrade";

interface SubscriptionInformationProps {
  hideButtons?: boolean;
  scrollHeight?: string;
}

export const SubscriptionInformation: React.FC<SubscriptionInformationProps> = ({
  hideButtons = false,
  scrollHeight = "calc(100vh - 14rem)",
}) => {
  const subscriptionInfo = useUserSubscriptionDetails();
  const features = subscriptionFeatures();

  return (
    <Box>
      <Stack
        color="gray.700"
        direction="row"
        width="100%"
        justifyContent={"space-between"}
        pl="1.5rem"
        pr=".5rem"
        pb=".5rem"
        fontWeight={"semibold"}
        fontSize="md">
        <Text>Feature</Text>
        <Stack direction="row">
          <Text width="8rem" textAlign={"center"}>
            Trial
          </Text>
          <Text width="8rem" textAlign={"center"}>
            Business
          </Text>
        </Stack>
      </Stack>
      <Box maxHeight={scrollHeight} overflowY="auto">
        <Table variant="simple">
          <Tbody>
            {features.map((feature, index) => (
              <Tr className="ch-subscription-feature" fontSize={"sm"} key={index}>
                <Td>{feature.name}</Td>
                <Td textAlign={"center"} width="8rem">
                  {feature.trial === true ? <Icon as={FaCheck} color="green.500" /> : feature.trial}
                </Td>
                <Td textAlign={"center"} width="8rem">
                  {feature.business === true ? (
                    <Icon as={FaCheck} color="green.500" />
                  ) : typeof feature.business === "string" ? (
                    <>
                      {feature.business.split("per month").map((part, index) => (
                        <Stack spacing="0" key={index}>
                          <Text>{part}</Text>
                          {index > 0 && (
                            <Text lineHeight={".6rem"} fontSize={"xs"} color="gray.500">
                              per month
                            </Text>
                          )}
                        </Stack>
                      ))}
                    </>
                  ) : null}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {!hideButtons && (
        <Stack direction="row" width="100%" justifyContent={"end"} pr="1.5rem" pt="1rem" spacing={"1.5rem"}>
          <Center>
            <UpgradePlanButton
              isDisabled={subscriptionInfo.internalPlanName === "business"}
              buttonSize="sm"
              style={{ backgroundColor: subscriptionInfo.internalPlanName === "business" ? "white" : "#efb943" }}
              label={
                subscriptionInfo.internalPlanName === "business"
                  ? `Subscribed to ${capitalize(subscriptionInfo.internalPlanName)}`
                  : `Upgrade to Business`
              }
            />
          </Center>
        </Stack>
      )}
    </Box>
  );
};
