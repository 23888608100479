import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
  Box,
  Badge,
  Flex,
  Stack,
  Input,
} from "@chakra-ui/react";
import type { ThemeTypings } from "@chakra-ui/react";
import { getActiveConfiguredWorkflowWithIntent, useConfiguredWorkflows } from "hooks/useConfiguredWorkflows";
import { ConfiguredWorkflowModal } from "./components/ConfiguredWorkflowModal";
import { useEntitlements, useButtonProps, useAppDispatch, useUserProfile } from "hooks";
import { ConfiguredWorkflowUpsertModal } from "./components/ConfiguredWorkflowUpsertModal";
import {
  addApprovedConfiguredWorkflow,
  createConfiguredWorkflow,
  deleteConfiguredWorkflowAction,
  downloadConfiguredWorkflow,
  downloadConfiguredWorkflows,
  removeApprovedConfiguredWorkflow,
  updateConfiguredWorkflow,
  updateConfiguredWorkflowState,
} from "state/configuredWorkflow/operations";
import type { Config, ConfiguredWorkflow, ConfiguredWorkflowCreationPayload, State } from "types/configuredWorkflows";
import { AdminTiles } from "screens/common/components";
import { FiGitMerge, FiList } from "react-icons/fi";
import capitalize from "lodash/capitalize";
import { useDownloadUsers, useGetUserName } from "hooks/useUsers";
import { BiCopy } from "react-icons/bi";
import { ConfigDiffPanel } from "./components/ConfigDiffPanel";
import { AiOutlineDownload } from "react-icons/ai";
import { saveToFile } from "../utils";
import { WorkflowConfigData } from "api/configuredWorkflows/models/ConfiguredWorkflow";
import { formatInTimeZone } from "date-fns-tz";
import { FaStickyNote } from "react-icons/fa";
import uniq from "lodash/uniq";
import { getWebsocketClient } from "api/websocket/client";
import { useIsWebsocketConnected } from "hooks/useWebsocket";
import { WorkflowConfigEventPayload } from "./models/WorkflowConfigEventPayload";

enum WorkflowConfigEventTypes {
  created = "created",
  updated = "updated",
  approved = "approved",
  unapproved = "unapproved",
  deleted = "deleted",
}

export const ConfiguredWorkflows = () => {
  const [isOpen, setIsOpen] = useState<"detail" | "upsert" | "delete" | "config_diff" | undefined>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState<ConfiguredWorkflow | undefined>();
  const [diffConfig, setDiffConfig] = useState<
    | {
        intent: string;
        originalConfig: Config;
        modifiedConfig: Config;
        sourceText?: string;
        targetText?: string;
      }
    | undefined
  >();
  const configuredWorkflows = useConfiguredWorkflows();
  const importInputRef = useRef<HTMLInputElement | null>(null);
  const { fullName, id: loggedUserId } = useUserProfile();
  const getUserName = useGetUserName();

  const {
    manage_configured_workflows_read: hasAdminConfiguredWorkflowRead,
    manage_configured_workflows_write: hasAdminConfiguredWorkflowWrite,
  } = useEntitlements();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();
  const toast = useToast();
  const commonButtonProps = useButtonProps("sm", "primary");
  const isWebsocketConnected = useIsWebsocketConnected();
  const selectedIdRef = useRef<string | null>(null);
  const mounted = useRef(false);

  const onOpenUpsert = useCallback((id?: string) => {
    if (id) {
      setSelectedId(id);
    }

    setIsOpen("upsert");
  }, []);

  const onOpenDelete = useCallback((id: any) => {
    setSelectedId(id);
    setIsOpen("delete");
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(undefined);
    setInitialValues(undefined);
    setDiffConfig(undefined);
  }, []);

  const onDelete = useCallback(async () => {
    if (!selectedId) {
      return;
    }

    const deleteResponse = await dispatch(deleteConfiguredWorkflowAction({ id: selectedId }));

    if (!deleteResponse.payload) {
      const { error } = deleteResponse as { error: { message: string } };

      toast({
        title: "Configured Workflow",
        description: error.message,
        status: "error",
        duration: 15000,
        isClosable: true,
      });

      return;
    }

    toast({
      title: "Configured Workflow",
      description: "Configured workflow deleted.",
      status: "success",
      duration: 15000,
      isClosable: true,
    });

    onClose();
  }, [dispatch, onClose, toast, selectedId]);

  const onSubmit = useCallback(
    async (values: ConfiguredWorkflowCreationPayload, closePanel = true) => {
      const isUpdate = !!values.id;

      const response = await dispatch(isUpdate ? updateConfiguredWorkflow(values) : createConfiguredWorkflow(values));

      if (!response.payload) {
        const {
          error: { message, name },
        } = response as { error: Error };
        const toastMessage = (() => {
          if (name === "duplicate_key") {
            return "User intent must be unique. Please try again.";
          }

          return message;
        })();

        toast({
          title: "Configured Workflow",
          description: toastMessage,
          status: "error",
          duration: 15000,
          isClosable: true,
        });

        return;
      }

      toast({
        title: "Configured Workflow",
        description: isUpdate ? "Configured workflow updated successfully" : "Configured workflows created successfully",
        status: "success",
        duration: 15000,
        isClosable: true,
      });

      if (closePanel) {
        onClose();
      }
    },
    [dispatch, onClose, toast]
  );

  const onSubmitState = useCallback(
    async ({ id: configuredWorkflowId, state, fromVersion }: { id: string; state: "active" | "draft"; fromVersion?: string }) => {
      const response = await dispatch(updateConfiguredWorkflowState({ configuredWorkflowId, state, fromVersion }));

      if (response.type === updateConfiguredWorkflowState.rejected.type) {
        const {
          error: { message },
        } = response as { error: Error };

        toast({
          title: "Configured Workflow",
          description: message,
          status: "error",
          duration: 15000,
          isClosable: true,
        });

        return;
      }

      toast({
        title: "Configured Workflow",
        description: "Configured workflow updated successfully",
        status: "success",
        duration: 15000,
        isClosable: true,
      });

      dispatch(downloadConfiguredWorkflows());
    },
    [dispatch, toast]
  );

  const importWorkflowConfig = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!evt.target.files || !evt.target.files[0]) {
      return;
    }

    const file = evt.target.files[0];
    const reader = new FileReader();

    evt.target.value = "";

    reader.onload = (e) => {
      try {
        if (!e.target?.result || typeof e.target.result !== "string") {
          return;
        }

        const data = JSON.parse(e.target.result);
        const validate = WorkflowConfigData.validate(data);

        if (validate.success) {
          const dateWithTimezone = formatInTimeZone(new Date(), "America/Vancouver", "do MMM yyyy hh:mm a");
          const environment = file.name.split("-")[0];
          const { notes, ...payload } = validate.value;

          const workflowPayload: ConfiguredWorkflowCreationPayload = {
            userIntent: payload.userIntent,
            config: payload.config,
            onlyAvailableToUserIds: [],
            notes: `${notes ? notes + "\n\n" : ""}--- Imported from ${environment} by ${
              fullName ?? "[unknown user]"
            } on ${dateWithTimezone}`,
          };

          onSubmit(workflowPayload, false);
        } else {
          toast({
            title: "Configured Workflow",
            description: "Invalid JSON file",
            status: "error",
            duration: 15000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);

        toast({
          title: "Configured Workflow",
          description: "Error parsing JSON",
          status: "error",
          duration: 15000,
          isClosable: true,
        });
      }
    };

    reader.readAsText(file);
  };

  const customOptions = useMemo(() => {
    return [
      {
        label: (row: ConfiguredWorkflow) => row.notes ?? "No notes",
        icon: FaStickyNote,
        hasPermission: hasAdminConfiguredWorkflowRead,
        isHidden: (row: ConfiguredWorkflow) => {
          return !row.notes;
        },
      },
      {
        label: "Show Config Diff",
        icon: FiGitMerge,
        hasPermission: hasAdminConfiguredWorkflowRead,
        onClick: (row: ConfiguredWorkflow) => {
          const activeConfig = getActiveConfiguredWorkflowWithIntent(row.userIntent, configuredWorkflows)?.config;

          if (!activeConfig) {
            return;
          }

          setDiffConfig({
            ...(row.state === "draft"
              ? { modifiedConfig: row.config, originalConfig: activeConfig, sourceText: "Active", targetText: "Draft" }
              : {
                  modifiedConfig: activeConfig,
                  originalConfig: row.config,
                  sourceText: "Backup",
                  targetText: "Active",
                }),
            intent: row.userIntent,
          });
          setIsOpen("config_diff");
        },
        isHidden: (row: ConfiguredWorkflow) => {
          return (
            (row.state !== "draft" && row.state !== "backup") || !getActiveConfiguredWorkflowWithIntent(row.userIntent, configuredWorkflows)
          );
        },
      },
      {
        label: "Duplicate Workflow Config",
        icon: BiCopy,
        hasPermission: hasAdminConfiguredWorkflowWrite,
        onClick: (row: ConfiguredWorkflow) => {
          setInitialValues(row);

          onOpenUpsert();
        },
      },
      {
        label: "Open Detail View",
        icon: FiList,
        hasPermission: hasAdminConfiguredWorkflowRead,
        onClick: (configuredWorkflow: ConfiguredWorkflow) => {
          if (configuredWorkflow.id) {
            setSelectedId(configuredWorkflow.id);
          }

          setIsOpen("detail");
        },
      },
      {
        label: "Export Config",
        icon: AiOutlineDownload,
        hasPermission: hasAdminConfiguredWorkflowRead,
        onClick: (cw: ConfiguredWorkflow) => {
          saveToFile(
            JSON.stringify(
              {
                userIntent: cw.userIntent,
                config: cw.config,
                notes: cw.notes,
              },
              null,
              2
            ),
            `${cw.userIntent}-workflow-config`,
            { extension: "workflow" }
          );
        },
      },
    ];
  }, [configuredWorkflows, hasAdminConfiguredWorkflowRead, hasAdminConfiguredWorkflowWrite, onOpenUpsert]);

  const customFields = useMemo(() => {
    const rowStateColor = (state?: State): ThemeTypings["colorSchemes"] => {
      switch (state) {
        case "active": {
          return "green";
        }
        case "draft": {
          return "yellow";
        }
        case "deleted": {
          return "red";
        }
        case "backup": {
          return "blue";
        }
        default:
          return "gray";
      }
    };

    return [
      {
        field: "State",
        value: (row: ConfiguredWorkflow) => {
          return <Badge colorScheme={rowStateColor(row.state)}>{capitalize(row.state)}</Badge>;
        },
      },
      {
        field: "Enabled for",
        value: (row: ConfiguredWorkflow) => {
          if (row.state === "active") {
            return "Everyone";
          } else
            return (
              <Flex direction={"column"}>
                {row.onlyAvailableToUserIds && row.onlyAvailableToUserIds.length > 0
                  ? row.onlyAvailableToUserIds.map((userId) => (
                      <Box key={userId}>
                        <Badge>{getUserName(userId)}</Badge>
                      </Box>
                    ))
                  : "No one"}
              </Flex>
            );
        },
      },
    ];
  }, [getUserName]);

  const usersToDownload = useMemo(
    () =>
      uniq(
        configuredWorkflows.flatMap(({ onlyAvailableToUserIds }) =>
          onlyAvailableToUserIds ? onlyAvailableToUserIds.filter((id) => id !== "system") : []
        )
      ),
    [configuredWorkflows]
  );

  useDownloadUsers(usersToDownload);

  useEffect(() => {
    if (isOpen === undefined) {
      setTimeout(() => {
        setSelectedId(null);
      }, 500);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isWebsocketConnected) {
      return;
    }

    if (mounted.current) {
      // Is a reconnect, then refresh list
      dispatch(downloadConfiguredWorkflows());
    } else {
      mounted.current = true;
    }

    const subscription = getWebsocketClient().subscribeToEvent(
      "workflow-config/#",
      (message, destination) => {
        const validation = WorkflowConfigEventPayload.validate(message);

        if (!validation.success) {
          console.error("Invalid message received from websocket", message);
          return;
        }

        const { workflowConfigId, userId, userName } = validation.value;

        if (loggedUserId === userId) {
          return;
        }

        const destinationArray = destination.split("/");
        const type = destinationArray[destinationArray.length - 1];

        switch (type) {
          case WorkflowConfigEventTypes.created: {
            dispatch(downloadConfiguredWorkflow({ id: workflowConfigId }));
            break;
          }
          case WorkflowConfigEventTypes.updated: {
            if (selectedIdRef.current === workflowConfigId) {
              toast({
                title: "Workflow Config",
                description: `This workflow config was updated by ${userName}.`,
                status: "info",
                duration: 15000,
                isClosable: true,
              });
            }

            dispatch(downloadConfiguredWorkflow({ id: workflowConfigId }));

            break;
          }
          case WorkflowConfigEventTypes.approved: {
            dispatch(addApprovedConfiguredWorkflow({ workflowConfigId, userId, userName }));
            break;
          }
          case WorkflowConfigEventTypes.unapproved: {
            dispatch(removeApprovedConfiguredWorkflow({ workflowConfigId, userId }));
            break;
          }
          case WorkflowConfigEventTypes.deleted: {
            if (selectedIdRef.current === workflowConfigId) {
              toast({
                title: "Workflow Config",
                description: `This config was deleted by ${userName}.`,
                status: "info",
                duration: 15000,
                isClosable: true,
              });
            }

            dispatch(deleteConfiguredWorkflowAction({ id: workflowConfigId, skipServerDelete: true }));
            break;
          }
          default:
            break;
        }
      },
      false
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [isWebsocketConnected, loggedUserId, dispatch, toast]);

  useEffect(() => {
    if (selectedId) {
      selectedIdRef.current = selectedId;
    } else {
      selectedIdRef.current = null;
    }
  }, [selectedId]);

  return (
    <Box>
      <Stack mb={"1rem"} direction="row">
        <Box>
          <Input ref={importInputRef} onChange={(evt) => importWorkflowConfig(evt)} type="file" accept=".workflow" hidden />
          <Button
            onClick={() => {
              importInputRef.current?.click();
            }}
            {...commonButtonProps}>
            Import
          </Button>
        </Box>
        <Box>
          <Button
            {...commonButtonProps}
            onClick={() => {
              onOpenUpsert();
            }}>
            Create Workflow Config
          </Button>
        </Box>
      </Stack>
      <AdminTiles<ConfiguredWorkflow>
        items={configuredWorkflows}
        defaultSortByKey="lastUpdatedDate"
        fieldsToRender={[]}
        filterByFields={["userIntent", "lastUpdatedByUserName"]}
        sortByFields={["userIntent", "lastUpdatedByUserName", "lastUpdatedDate"]}
        inputFilterPlaceholder="Search by workflow name or last update name"
        customFields={customFields}
        onClickDelete={onOpenDelete}
        onClickEdit={(id) => {
          const configuredWorkflow = configuredWorkflows.find((cw) => cw.id === id);

          if (configuredWorkflow?.canBeEdited === false) {
            setSelectedId(id);
            setIsOpen("detail");

            return;
          }

          onOpenUpsert(id);
        }}
        hasWrite={hasAdminConfiguredWorkflowWrite}
        hasRead={hasAdminConfiguredWorkflowRead}
        tileTitle="New Workflow"
        tileTitleKey="userIntent"
        customOptions={customOptions}
        keyName="id"
        customFilters={[
          {
            type: "multi-select",
            key: "state",
            defaultOptions: ["active", "draft"],
            options: [
              { label: "Active", value: "active" },
              { label: "Draft", value: "draft" },
              { label: "Deleted", value: "deleted" },
              { label: "Backup", value: "backup" },
            ],
          },
        ]}
        showViewType={false}
        viewType="table"
      />

      {isOpen === "detail" && <ConfiguredWorkflowModal id={selectedId} isOpen onClose={onClose} />}
      {isOpen === "upsert" && (
        <ConfiguredWorkflowUpsertModal
          {...(selectedId && { id: selectedId })}
          {...(initialValues && { initialValues })}
          isOpen
          onClose={onClose}
          onSubmit={onSubmit}
          onSubmitState={onSubmitState}
        />
      )}
      {isOpen === "config_diff" && diffConfig && (
        <ConfigDiffPanel
          isOpen
          userIntent={diffConfig.intent}
          modifiedConfig={diffConfig.modifiedConfig}
          originalConfig={diffConfig.originalConfig}
          sourceText={diffConfig.sourceText}
          targetText={diffConfig.targetText}
          onClose={onClose}
          isLoading={false}
        />
      )}

      <AlertDialog isOpen={isOpen === "delete"} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="sm" fontWeight="bold">
              Delete Configured Workflow
            </AlertDialogHeader>

            <AlertDialogBody fontSize="sm">Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button {...commonButtonProps} ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button {...commonButtonProps} onClick={onDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};
