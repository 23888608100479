import { prependProxyBaseUrl, request } from "./request";

export interface SuggestedQuestionResponse {
  status: string;
  number_of_questions: number;
  message: string;
  suggested_questions: SuggestedQuestion[];
}

export interface SuggestedQuestion {
  question: string;
  label?: string;
  matchFilter?: string[]; // this array is used for exact matching specific content of the question or record data
  focus?: string;
}
export type SuggestedQuestions = SuggestedQuestion[];
const SUGGESTED_QUESTIONS_ENDPOINT = prependProxyBaseUrl("/api/questions-suggestions");

export async function fetchDueDiligenceSuggestedQuestions(): Promise<SuggestedQuestionResponse | null> {
  try {
    const response: SuggestedQuestionResponse = await request()
      .url(`${SUGGESTED_QUESTIONS_ENDPOINT}/?question_category=dd_suggestion`)
      .get()
      .json();
    return response;
  } catch (error) {
    console.error(`Failed to get suggested question data: ${error}`);
    return null;
  }
}
