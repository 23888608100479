import { Badge, Box, Button, Flex, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import { createTicker, getPaginatedTickers, importTickers, updateTicker } from "api/tickers";
import { CreateTickerPanel } from "./components/CreateTicker";
import { ImportTickersPanel } from "./components/ImportTickersPanel";
import { TickersFilters } from "./components/TickersFilters";
import { TickersFiltersContextProvider, useTickersFiltersContext } from "./contexts/TickersFiltersContext";
import { TickerStatus } from "api/tickers/models/TickerStatus";
import { TypingIndicator } from "screens/thread/components/cells/components";
import { UpdateTickerPanel } from "./components/UpdateTicker";
import { useButtonProps, useInfiniteLoading } from "hooks";
import capitalize from "lodash/capitalize";
import React, { useCallback, useEffect } from "react";
import type { CreateTickerForm } from "./components/CreateTicker";
import type { CreateTickerRequest } from "api/tickers/models/CreateTickerRequest";
import type { ImportTickersForm } from "./components/ImportTickersPanel";
import type { Ticker } from "api/tickers/models/Ticker";
import type { UpdateTickerForm } from "./components/UpdateTicker";
import type { UpdateTickerRequest } from "api/tickers/models/UpdateTickerRequest";
import { getTickerTypeOption } from "./utils/getTickerTypeOption";

const PAGE_SIZE = 20;

const TickersInner = () => {
  const { status, exchange, searchQuery } = useTickersFiltersContext();
  const bgColor = useColorModeValue("gray.200", "gray.700");
  const titleColor = useColorModeValue("charli.lightGray", "gray.500");
  const commonButtonProps = useButtonProps("sm", "primary");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isOpen: isOpenEdit, onClose: onCloseEdit, onOpen: onOpenEdit } = useDisclosure();
  const { isOpen: isOpenCreate, onClose: onCloseCreate, onOpen: onOpenCreate } = useDisclosure();
  const toast = useToast();
  const [isLoadingForm, setIsLoadingForm] = React.useState(false);
  const [tempTicker, setTempTicker] = React.useState<Ticker | undefined>(undefined);

  const fetchMore = useCallback(
    async (nextToken: string | null) => {
      const validateStatus = TickerStatus.validate(status);

      const response = await getPaginatedTickers({
        ...(nextToken && { token: nextToken }),
        ...(status && validateStatus.success && { status: validateStatus.value }),
        ...(searchQuery && { query: searchQuery }),
        ...(exchange && { exchange }),
        limit: PAGE_SIZE,
      });

      return { data: response.data, nextToken: response.nextToken };
    },
    [status, exchange, searchQuery]
  );

  const {
    loading: isLoading,
    items,
    lastMessageObserverRef: lastMessageObserver,
    hasNextPage,
    clear,
  } = useInfiniteLoading<Ticker>({
    loadItems: fetchMore,
  });

  const handleSubmitImport = (values: ImportTickersForm) => {
    const { file, exchange } = values;

    if (!file) {
      return;
    }

    setIsLoadingForm(true);
    importTickers({ exchange, file })
      .then(() => {
        toast({
          title: "Tickers imported",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onClose();
        clear();
      })
      .catch((error) => {
        toast({
          title: "Error importing tickers",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  const handleSubmitUpdate = (values: UpdateTickerForm, id?: string) => {
    if (!id) {
      return;
    }

    const payload: UpdateTickerRequest = {
      name: values.name,
      exchange: values.exchange,
      status: values.status,
      ...(values.type !== "null" && { type: values.type }),
    };

    setIsLoadingForm(true);
    updateTicker({ id, ticker: payload })
      .then(() => {
        toast({
          title: "Ticker updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onCloseEdit();
        clear();
      })
      .catch((error) => {
        toast({
          title: "Error updating ticker",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  const handleSubmitCreate = (values: CreateTickerForm) => {
    const payload: CreateTickerRequest = {
      name: values.name,
      exchange: values.exchange,
      symbol: values.symbol,
      type: values.type,
    };

    setIsLoadingForm(true);
    createTicker({ ticker: payload })
      .then(() => {
        toast({
          title: "Ticker created",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onCloseCreate();
        clear();
      })
      .catch((error: Error) => {
        toast({
          title: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  useEffect(() => {
    clear();
  }, [clear, status, exchange, searchQuery]);

  return (
    <>
      <ImportTickersPanel isLoading={isLoadingForm} onSubmit={handleSubmitImport} isOpen={isOpen} onClose={onClose} />
      <TickersFilters isLoading={isLoading} />
      <UpdateTickerPanel
        isLoading={isLoadingForm}
        ticker={tempTicker}
        isOpen={isOpenEdit}
        onClose={onCloseEdit}
        onSubmit={handleSubmitUpdate}
      />

      <CreateTickerPanel
        isLoading={isLoadingForm}
        onSubmit={(values) => handleSubmitCreate(values)}
        isOpen={isOpenCreate}
        onClose={onCloseCreate}
      />

      <Flex mb={"1rem"}>
        <Button mr={"0.5rem"} onClick={() => onOpen()} {...commonButtonProps}>
          Import Tickers
        </Button>
        <Button onClick={() => onOpenCreate()} {...commonButtonProps}>
          Create Ticker
        </Button>
      </Flex>

      {/* Table header */}
      <Box
        fontSize={"small"}
        borderBottomColor={bgColor}
        borderBottom={`2px solid`}
        mb={1}
        color={titleColor}
        display={"flex"}
        justifyContent="space-between">
        <Box width={200}>Name</Box>
        <Box flex={1}>Symbol</Box>
        <Box flex={1}>Exchange</Box>
        <Box flex={1}>Status</Box>
        <Box flex={1}>Type</Box>
        <Box flex={1}>Updated date</Box>
        <Box flex={1}>Update by</Box>
      </Box>

      {items.map((item) => (
        <Flex p={1} alignItems={"center"} key={`${item.id}`}>
          <Box
            onClick={(evt) => {
              evt.preventDefault();
              setTempTicker(item);
              onOpenEdit();
            }}
            cursor={"pointer"}
            fontSize="sm"
            _hover={{ bgColor }}
            display={"flex"}
            justifyContent="space-between"
            flex={1}>
            <Box width={200} overflow={"hidden"} wordBreak="break-word" textOverflow={""}>
              {item.name}
            </Box>
            <Box flex={1} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
              {item.symbol}
            </Box>
            <Box flex={1} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
              {item.exchange}
            </Box>
            <Box flex={1} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
              <Badge colorScheme={item.status === "active" ? "green" : "red"}>{capitalize(item.status)}</Badge>
            </Box>
            <Box flex={1} overflow={"hidden"} whiteSpace="nowrap" textOverflow={"ellipsis"}>
              {item.type ? getTickerTypeOption(item.type).label : "N/A"}
            </Box>
            <Box flex={1}>{item.lastUpdatedDate ?? "N/A"}</Box>
            <Box flex={1}>{item.lastUpdatedByUser ?? "N/A"}</Box>
          </Box>
        </Flex>
      ))}

      {hasNextPage && (
        <Flex visibility={isLoading ? "visible" : "hidden"} height={"3rem"} ref={lastMessageObserver} align="center" justify="center">
          <TypingIndicator />
        </Flex>
      )}
    </>
  );
};

export const Tickers = () => {
  return (
    <TickersFiltersContextProvider>
      <TickersInner />
    </TickersFiltersContextProvider>
  );
};
