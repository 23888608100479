import React, { useCallback, useContext } from "react";
import { useConversationContext } from "screens/thread/ConversationContext";
import { useCollectionKey, useConversation, useEntitlements, usePortfolioCollections, useProjectParams } from "hooks";
import { useDispatch } from "react-redux";
import type { RequestEntities } from "types/charliui";
import { sendMessage } from "state/websocket/operations";
import { useFeatureUsage } from "hooks/useFeatureUsage";
import { Intent } from "types/intent";
import { v4 as uuid } from "uuid";
import onSubmitDueDiligence from "screens/collection/components/useSubmitDueDiligence";
import { ToastMessageContent } from "screens/common/components";
import { useToast } from "@chakra-ui/react";
import { InputBarDropzoneContext } from "screens/conversation/components/InputBar/InputBarDropzoneContext";
import { useNavigate } from "react-router-dom";
import { useConversationDialogUtils } from "./useConversationDialogUtils";

interface Props {
  questionText?: string;
  selectedFocus?: string;
  afterSubmit?: () => void;
  conversationId?: string;
}

export const useConversationDialogSubmitFunctions = ({
  questionText,
  selectedFocus,
  afterSubmit,
  conversationId: conversationIdProp,
}: Props) => {
  const { projectId, projectFilter, isPortfolios, parentRoute } = useProjectParams();
  const { onConversationOpen, isConversationOpen, onUpgradeModalOpen, conversationId, isOnboardingModalOpen } = useConversationContext();
  const projectConversationId = useCollectionKey(projectId, "conversationId") || uuid();
  const currentConversationID = conversationIdProp || projectConversationId || conversationId;
  const conversation = useConversation(currentConversationID);
  const { files, resetFiles } = useContext(InputBarDropzoneContext);

  const portfolioCollections = usePortfolioCollections();
  const firstPortfolioCollection = portfolioCollections && portfolioCollections[0] ? portfolioCollections[0].id : undefined;
  const portfolioId =
    parentRoute === "home" && portfolioCollections.length === 1
      ? firstPortfolioCollection
      : projectFilter && isPortfolios
      ? projectFilter
      : undefined;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const projectUsage = useFeatureUsage(Intent.createDueDiligenceProject);
  const projectQuestionUsage = useFeatureUsage(Intent.generateAnswer);
  const { hasExceededMaxProjects, maxAllowedProjects } = useConversationDialogUtils();
  const { bypass_usage_limits: canBypassUsageLimits } = useEntitlements();

  const onSubmitDefaultRequest = useCallback(
    (questionText: string) => {
      if (!selectedFocus || !currentConversationID) return;
      const entities: RequestEntities = [];
      if (projectId) entities.push({ entity: "collection_id", value: projectId });

      dispatch(
        sendMessage({
          conversationId: currentConversationID,
          intent: `${questionText}`,
          entities: [...entities],
          files: files,
        })
      );

      if (files.length) resetFiles();
      onConversationOpen(currentConversationID);
    },
    [selectedFocus, currentConversationID, projectId, dispatch, files, resetFiles, onConversationOpen]
  );

  // SEND GENERATE ANSWER REQUEST
  const onSubmitGenerateAnswer = useCallback(
    (questionText: string, selectedFocus?: string) => {
      if (!questionText || !projectId || !selectedFocus || !currentConversationID) {
        console.warn("Missing required parameters for onSubmitGenerateAnswer");
        console.log(JSON.stringify({ questionText, projectId, selectedFocus, currentConversationID }));
        return;
      }

      if (
        !canBypassUsageLimits &&
        projectQuestionUsage &&
        projectQuestionUsage.allowed > 0 &&
        projectQuestionUsage.used >= projectQuestionUsage.allowed
      ) {
        onUpgradeModalOpen();
        return;
      }
      const entities: RequestEntities = [];
      entities.push({ entity: "collection_id", value: projectId });
      entities.push({ entity: "question", value: questionText.trim() });
      entities.push({ entity: "answer_focus", value: selectedFocus });
      entities.push({ entity: "asked_by_user", value: "true" });
      entities.push({ entity: "answer_format", value: "html" });

      dispatch(
        sendMessage({
          conversationId: currentConversationID,
          intent: `/generate_answer`,
          entities: [...entities],
        })
      );

      onConversationOpen(currentConversationID);
    },
    [projectId, currentConversationID, canBypassUsageLimits, projectQuestionUsage, dispatch, onConversationOpen, onUpgradeModalOpen]
  );

  // SEND DUE DILIGENCE PROJECT REQUEST
  const onSubmitDueDiligenceProject = useCallback(
    (questionText: string, questionFocus?: string, afterSubmit?: () => void, reRunProjectId?: string, reRunPortfolioId?: string) => {
      console.log({ canBypassUsageLimits, projectUsage });

      if (!canBypassUsageLimits && projectUsage && projectUsage.allowed > 0 && projectUsage.used >= projectUsage.allowed) {
        onUpgradeModalOpen();
        return;
      }

      if (hasExceededMaxProjects) {
        toast({
          render: ({ onClose }) => (
            <ToastMessageContent
              message={`You can only have ${maxAllowedProjects} active due diligence projects at a time`}
              onClick={() => {
                onClose();
                onUpgradeModalOpen();
              }}
              onClose={onClose}
            />
          ),
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      afterSubmit && afterSubmit();

      onSubmitDueDiligence({
        companyTickerOrName: questionText,
        companyStockExchange: questionFocus || "",
        isTicker: true,
        dispatch,
        onConversationOpen: isOnboardingModalOpen ? undefined : onConversationOpen,
        onSubmitted: projectId ? () => navigate(-1) : undefined,
        additionalEntities: [],
        portfolioId: reRunPortfolioId ? reRunPortfolioId : portfolioId,
        reRunProjectId: reRunProjectId ? reRunProjectId : undefined,
      });

      toast.closeAll();
      toast({
        render: ({ onClose }) => (
          <ToastMessageContent
            message={`I'll get on to creating the due diligence project now. When it's complete, I will email you a report.`}
            onClick={() => {
              onClose();
            }}
            onClose={onClose}
          />
        ),
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    },
    [
      canBypassUsageLimits,
      dispatch,
      hasExceededMaxProjects,
      isOnboardingModalOpen,
      maxAllowedProjects,
      navigate,
      onConversationOpen,
      onUpgradeModalOpen,
      portfolioId,
      projectId,
      projectUsage,
      toast,
    ]
  );

  // DETERMINE SUBMIT FUNCTION TO USE
  const currentSubmitFunction = useCallback(
    (questionText: string) => {
      const determineSubmitFunction = () => {
        if (
          conversation.conversationState === "action" ||
          conversation.conversationState === "in_progress" ||
          questionText.startsWith("/") ||
          files.length > 0
        ) {
          return () => onSubmitDefaultRequest(questionText);
        }
        if (projectId) {
          return () => onSubmitGenerateAnswer(questionText, selectedFocus);
        }

        const stockExchange = selectedFocus && !["analytical", "sentiment"].includes(selectedFocus) ? selectedFocus : undefined;
        if (stockExchange) {
          return () => onSubmitDueDiligenceProject(questionText, stockExchange, afterSubmit);
        }

        console.log("Unable to determine submit function");

        return undefined;
      };

      const submitFunction = determineSubmitFunction();
      if (submitFunction) {
        submitFunction();
      }
    },
    [
      conversation.conversationState,
      files.length,
      projectId,
      onSubmitDefaultRequest,
      onSubmitGenerateAnswer,
      selectedFocus,
      onSubmitDueDiligenceProject,
      afterSubmit,
    ]
  );

  return {
    currentSubmitFunction,
    onSubmitDueDiligenceProject,
    currentConversationID,
    conversation,
    onConversationOpen,
    isConversationOpen,
    questionText,
  };
};
