import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Select as FilterSelect } from "chakra-react-select";
import { Button, Input, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useButtonProps } from "hooks";
import { QueryParamFilters, useTickersFiltersContext } from "../contexts/TickersFiltersContext";
import { TickerStatus } from "api/tickers/models/TickerStatus";
import capitalize from "lodash/capitalize";

interface IProps {
  isLoading: boolean;
  hideClearButton?: boolean;
  totalCount?: number;
  filterCount?: number;
  refresh?: () => void;
}

interface FilterOption {
  value: string;
  label: string;
}

const StatusOptions = [
  { label: "All", value: "all" },
  ...TickerStatus.alternatives.map(({ value }) => ({ value, label: capitalize(value) })),
];

const ExchangeOptions = [
  { label: "All", value: "all" },
  { label: "NYSE", value: "NYSE" },
  { label: "NASDAQ", value: "NASDAQ" },
  { label: "TSX", value: "TSX" },
];

export const TickersFilters = (props: IProps) => {
  const { isLoading, hideClearButton, filterCount, totalCount, refresh } = props;
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const navigate = useNavigate();
  const headingTextColor = useColorModeValue("gray.600", "gray.100");
  const secondaryButtonProps = useButtonProps("sm", "secondary");
  const [queryInput, setQueryInput] = useState<string>("");

  const { status, exchange } = useTickersFiltersContext();

  const selectedStatus = useMemo(() => {
    const label =
      StatusOptions.find((taskStatus) => (!status && taskStatus.value === "all") || taskStatus.value === status)?.label ?? status;
    return [{ label, value: status }];
  }, [status]);

  const selectedExchange = useMemo(() => {
    const label =
      ExchangeOptions.find((exchangeOption) => (!exchange && exchangeOption.value === "all") || exchangeOption.value === exchange)?.label ??
      exchange;
    return [{ label, value: exchange }];
  }, [exchange]);

  const handleFilter = (option: FilterOption | null, type: QueryParamFilters) => {
    if (option === null || option.value === "all") {
      searchParams.delete(type);
    } else {
      searchParams.set(type, option.value);
    }

    navigate({ pathname: location.pathname, search: searchParams.toString() });
  };

  const clearFilters = useCallback(() => {
    navigate({ pathname: location.pathname, search: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const debounced = setTimeout(() => {
      if (queryInput) {
        searchParams.set(QueryParamFilters.query, queryInput);
      } else {
        searchParams.delete(QueryParamFilters.query);
      }

      navigate({ pathname: location.pathname, search: searchParams.toString() });
    }, 500);

    return () => {
      clearTimeout(debounced);
    };
  }, [queryInput, navigate, searchParams, location.pathname]);

  return (
    <Stack width="100%" justifyContent="space-between" pb=".5rem" spacing="1rem">
      <Stack direction="row" width="100%" spacing="1rem" justifyContent={"space-between"} alignContent="flex-start">
        <Stack width="100%">
          <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
            Filter by status
          </Text>
          <FilterSelect
            className="ch-multi-select"
            useBasicStyles
            size="sm"
            selectedOptionStyle="check"
            options={StatusOptions}
            onChange={(newStatus) => {
              handleFilter(newStatus as FilterOption, QueryParamFilters.status);
            }}
            value={selectedStatus}
            isDisabled={isLoading}
            isClearable
          />
        </Stack>
        <Stack width="100%">
          <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
            Filter by query
          </Text>
          <Input
            isDisabled={isLoading}
            value={queryInput}
            onChange={(evt) => {
              setQueryInput(evt.target.value);
            }}
            size={"sm"}
            placeholder="Search by name or symbol"
          />
        </Stack>
        <Stack width="100%">
          <Text fontSize="sm" fontWeight="600" color={headingTextColor}>
            Filter by exchange
          </Text>
          <FilterSelect
            className="ch-multi-select"
            useBasicStyles
            size="sm"
            selectedOptionStyle="check"
            options={ExchangeOptions}
            onChange={(newExchange) => {
              handleFilter(newExchange as FilterOption, QueryParamFilters.exchange);
            }}
            value={selectedExchange}
            isDisabled={isLoading}
            isClearable
          />
        </Stack>
      </Stack>

      <Stack direction="row" width="100%" spacing="1rem" justifyContent={refresh ? "space-between" : "flex-end"}>
        {refresh && (
          <Stack direction="row">
            <Button width="100%" {...secondaryButtonProps} isDisabled={isLoading} onClick={() => refresh()}>
              Refresh Tasks
            </Button>
          </Stack>
        )}
        {filterCount && totalCount && (
          <Stack direction="row" spacing="1rem">
            <Text fontSize="xs" color={headingTextColor} lineHeight="2rem">
              {`Filtering ${filterCount} of ${totalCount} items`}
            </Text>
            {!hideClearButton && (
              <Button {...secondaryButtonProps} isDisabled={isLoading} onClick={() => clearFilters()}>
                Clear Filters
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
