import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Stack, Text, Button, Center, ModalCloseButton, Checkbox, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { useButtonProps, useEntitlementKey, useUserPreference, useUserSubscriptionDetails } from "hooks";
import { useDispatch } from "react-redux";
import { ConversationContext } from "screens/thread/ConversationContext";
import { CommonModal } from "screens/landing/components/popoverComponent/CommonModal";
import iconWord from "screens/common/static/misc/icon_word.svg";
import iconExcel from "screens/common/static/misc/icon_excel.svg";
import iconPdf from "screens/common/static/misc/icon_pdf.svg";
import iconPP from "screens/common/static/misc/icon_powerpoint.svg";
import iconTemplates from "screens/common/static/misc/icon_templates.svg";
import iconSnowflake from "screens/common/static/misc/icon_snowflake.svg";
import iconSqlServer from "screens/common/static/misc/icon_sql_server.svg";
import iconDatabricks from "screens/common/static/misc/icon_databricks.svg";
import type { TileSelectorProps } from "screens/landing/components/popoverComponent/TileSelector";
import { TileSelector } from "screens/landing/components/popoverComponent/TileSelector";
import { updateTypedUserPreference } from "state/userPreference/operations";
import type { UserPreferenceKey } from "types/userPreferences";
import { useRequestDefaultReport } from "screens/collection/components/useRequestDefaultReport";
import { useReportTemplatesList } from "hooks/useReportTemplates";

interface Props {
  collectionId: string;
  onClose: () => void;
  isOpen: boolean;
  title?: string;
}

const customReportTemplateNames = [
  "Stakeholders Template from CapTable",
  "Certificates Template from CapTable",
  "Awards Template from Cap Table",
  "Certificates Template from PDF",
  "Awards Template from PDF",
];

export const reportTypeTileSelectors = (currentPlan: string, defaultSelectedReport?: string, hasCustomReports?: boolean) => [
  {
    label: "Standard Adobe PDF report using the system template",
    entityName: "outputFormat",
    value: "PDF",
    image: iconPdf,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    default: defaultSelectedReport === "PDF",
  },
  {
    label: "Editable MS Word document using the system template",
    tooltip: currentPlan === "business" ? "" : "This feature is available on upgrade to Business",
    entityName: "outputFormat",
    value: "DOCX",
    image: iconWord,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: currentPlan === "business" ? false : true,
    default: defaultSelectedReport === "DOCX",
  },
  {
    label: "Editable data spreadsheet using the system template",
    tooltip: "This feature is available on upgrade to Premium or Enterprise",
    entityName: "outputFormat",
    value: "XLSX",
    image: iconExcel,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
  {
    label: "Editable MS PowerPoint presentation using the system template",
    tooltip: "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "",
    image: iconPP,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
  {
    label: "Custom file formats and custom templates",
    tooltip: hasCustomReports ? "" : "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "CUSTOM",
    image: iconTemplates,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: hasCustomReports ? false : true,
  },
];

export const cloudTypeTileSelectors = (): TileSelectorProps[] => [
  {
    label: "Push AI data to Snowflake data lake",
    tooltip: "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "",
    image: iconSnowflake,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
  {
    label: "Push AI data to Databricks data lake",
    tooltip: "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "",
    image: iconDatabricks,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
  {
    label: "Push AI data to SQL Server data base",
    tooltip: "This feature is available on upgrade to Enterprise",
    entityName: "outputFormat",
    value: "",
    image: iconSqlServer,
    imageDimensions: { width: "3.5rem", height: "3.5rem" },
    disabled: true,
  },
];

export const ProjectReportPanelNewReportModal = ({ collectionId, onClose, isOpen, title = "Generate AI Report" }: Props) => {
  const primaryButtonProps = useButtonProps("sm", "cta");
  const { setRequestEntities } = useContext(ConversationContext);
  const dispatch = useDispatch();
  const userDefaultReportTemplateType = useUserPreference("default_report_template_type");
  const subscriptionInfo = useUserSubscriptionDetails();
  const userDefaultReportType = String(userDefaultReportTemplateType && String(userDefaultReportTemplateType).trim());
  const [isDefaultReportChecked, setIsDefaultReportChecked] = useState(userDefaultReportType.length > 0);
  const hasCustomReports = useEntitlementKey("ui_enable_custom_reports");
  const [showCustomReportTiles, setShowCustomReportTiles] = useState(false);

  const reportTemplates = useReportTemplatesList();
  const filteredReportTemplates = useMemo(() => {
    if (!reportTemplates) {
      return [];
    }
    return reportTemplates.filter((template) => customReportTemplateNames.includes(template.name));
  }, [reportTemplates]);
  const customReportTileSelectorsMemo = useMemo(() => {
    return filteredReportTemplates.map((template) => {
      return {
        label: template.name,
        entityName: "report_template_id",
        value: template.id,
        image: iconExcel,
        imageDimensions: { width: "3.5rem", height: "3.5rem" },
        disabled: false,
      };
    });
  }, [filteredReportTemplates]);

  const [selectedReportTemplateId, setSelectedReportTemplateId] = useState<string | undefined>(undefined);
  const [selectedReportType, setSelectedReportType] = useState<string | undefined>(
    userDefaultReportTemplateType ? String(userDefaultReportTemplateType).trim() : undefined
  );

  const reportTypeTileSelectorsMemo = useMemo(
    () => reportTypeTileSelectors(subscriptionInfo?.internalPlanName, userDefaultReportType, hasCustomReports),
    [hasCustomReports, userDefaultReportType, subscriptionInfo?.internalPlanName]
  );
  const cloudTypeTileSelectorsMemo = useMemo(() => cloudTypeTileSelectors(), []);

  const defaultReportFunction = useRequestDefaultReport({
    collectionId,
    selectedReportTemplate: selectedReportTemplateId,
    selectedOutputFormat: selectedReportType,
  });
  const requestDefaultReport = useCallback(() => {
    return defaultReportFunction();
  }, [defaultReportFunction]);

  const handleOnChange = useCallback(
    (key: UserPreferenceKey, value: string) => {
      dispatch(
        updateTypedUserPreference({
          preferenceKey: key,
          value: value,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (showCustomReportTiles) {
      setSelectedReportType("XLSX");
      setSelectedReportTemplateId(customReportTileSelectorsMemo[0].value);
    } else {
      setSelectedReportType(reportTypeTileSelectorsMemo[0].value);
      setSelectedReportTemplateId(undefined);
    }
  }, [customReportTileSelectorsMemo, reportTypeTileSelectorsMemo, showCustomReportTiles]);

  useEffect(() => {
    if (!isOpen) {
      setRequestEntities([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <CommonModal
      minWidth={["90%", "50rem", "45rem"]}
      borderColor="#81c34c"
      isOpen={isOpen}
      onClose={onClose}
      modalHeader={
        <Stack
          color={"gray.800"}
          bgColor={"#81c34c"}
          direction={"row"}
          fontSize="md"
          textAlign="center"
          width="100%"
          height={"3rem"}
          justifyContent={"space-between"}
          px="1rem">
          <Center>
            <Text fontWeight={"semibold"} fontSize="lg">
              {title}
            </Text>
          </Center>
          <Center>
            <ModalCloseButton cursor="pointer" _hover={{}} />
          </Center>
        </Stack>
      }
      modalBody={
        <Stack justifyContent="space-between" spacing="0" height="32.5rem">
          <Tabs p="2rem">
            <TabList>
              <Tab onClick={() => setShowCustomReportTiles(false)}>Documents</Tab>
              <Tab>Cloud</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pt="2rem" px="0">
                {showCustomReportTiles ? (
                  <TileSelector
                    layout="grid"
                    tiles={customReportTileSelectorsMemo}
                    onSelectedTile={(tile) => {
                      setIsDefaultReportChecked(false);
                      setSelectedReportTemplateId(tile.value);
                      setSelectedReportType("XLSX");
                    }}
                  />
                ) : (
                  <TileSelector
                    layout="grid"
                    tiles={reportTypeTileSelectorsMemo}
                    onSelectedTile={(tile) => {
                      if (tile.value === "CUSTOM") {
                        setShowCustomReportTiles(true);
                      } else {
                        setIsDefaultReportChecked(false);
                        setSelectedReportType(tile.value);
                        handleOnChange("default_report_template_type", "");
                      }
                    }}
                  />
                )}
              </TabPanel>
              <TabPanel pt="2rem" px="0">
                <TileSelector layout="grid" tiles={cloudTypeTileSelectorsMemo} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
      }
      modalFooter={
        <Stack direction="row" justifyContent={"space-between"} width="100%" py="2rem" pr="1rem" pl="2rem">
          <Stack direction={"row"} spacing="1rem">
            <Checkbox
              isChecked={isDefaultReportChecked}
              onChange={(evt) => {
                handleOnChange("default_report_template_type", evt.target.checked && selectedReportType ? selectedReportType : "");
                setIsDefaultReportChecked(evt.target.checked);
              }}
              type="checkbox"
              id="ch-default-report-checkbox"
              borderColor="gray.400"
              boxShadow="none"
            />
            <Text fontSize="sm" lineHeight="2rem">
              Set selected report as default
            </Text>
          </Stack>
          <Button
            className="ch-publish-report-button"
            {...primaryButtonProps}
            mr={3}
            isDisabled={!selectedReportType || (selectedReportType === "CUSTOM" && !selectedReportTemplateId)}
            onClick={() => {
              setShowCustomReportTiles(false);
              requestDefaultReport();
              onClose();
            }}>
            Send Report
          </Button>
        </Stack>
      }
    />
  );
};
