import { Stack, Text, Image, Center, Box, Button, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { useButtonProps, useConfigMap, useGetViewConfig } from "hooks";
import { useConversationContext } from "screens/thread/ConversationContext";
import { ONBOARDING_MODEL_HEIGHT } from "./OnboardingModal";

export const OnboardingLearnMoreStep = () => {
  const configMap = useConfigMap();
  const homeOnboardingSteps = useGetViewConfig("onBoardingSteps", "home", configMap);
  const commonButtonProps = useButtonProps("sm", "cta");
  const { isOnboardingModalOpen, onOnboardingModalClose } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });

  return (
    <Stack justifyContent="space-between" spacing="0" height={ONBOARDING_MODEL_HEIGHT}>
      <Stack
        color="white"
        bgColor="#4799d4"
        direction={"row"}
        fontSize="md"
        textAlign="center"
        width="100%"
        height={"3rem"}
        justifyContent={"space-between"}
        px="1rem">
        <Center>
          <Text fontWeight={"semibold"} fontSize="lg">
            Learn more about Charli
          </Text>
        </Center>
      </Stack>
      <Stack spacing="3rem" px={isMobile ? ".5rem" : "2rem"} pt="2rem" height="100%" justifyContent={"space-between"}>
        <Stack spacing="2rem">
          <Text fontSize="md" width="100%" color={"gray.500"} textAlign="center">
            Now that you've put your Automated Analyst to work, learn how to interact with Charli and start asking questions.
          </Text>
          <Box px="1rem" cursor={"pointer"}>
            <Image
              width="100%"
              borderRadius={"md"}
              src={homeOnboardingSteps && homeOnboardingSteps[0].thumbnailSource}
              onClick={() => window.open(homeOnboardingSteps && homeOnboardingSteps[0].url, "_blank")}
            />
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent={"end"} width="100%" pb="1.5rem" pr="2rem">
        <Button
          {...commonButtonProps}
          id={`ch-close-onboarding-learn-more`}
          aria-label="submit"
          onClick={() => {
            isOnboardingModalOpen && onOnboardingModalClose();
          }}>
          {`Close Onboarding`}
        </Button>
      </Stack>
    </Stack>
  );
};
