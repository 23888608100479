import { Array } from "runtypes";
import { PaginatedTickersResponse } from "./models/PaginatedTickersResponse";
import { prependProxyBaseUrl, request } from "api/request";
import { Ticker } from "./models/Ticker";
import type { CreateTickerRequest } from "./models/CreateTickerRequest";
import type { TickerStatus } from "api/tickers/models/TickerStatus";
import type { UpdateTickerRequest } from "./models/UpdateTickerRequest";

const TickersQueryResponse = Array(Ticker);

const TICKERS_V2_ENDPOINT = prependProxyBaseUrl("/api/tickers/v2");

export async function getPaginatedTickers({
  limit = 10,
  query,
  token,
  status,
  exchange,
}: {
  limit?: number;
  query?: string;
  token?: string;
  status?: TickerStatus;
  exchange?: string;
}): Promise<PaginatedTickersResponse> {
  try {
    const response = await request()
      .url(`${TICKERS_V2_ENDPOINT}`)
      .query({
        limit,
        ...(query && { filterByQuery: query }),
        ...(token && { token }),
        ...(status && { filterByStatus: status }),
        ...(exchange && { filterByExchange: exchange }),
      })
      .get()
      .json();

    const validate = PaginatedTickersResponse.validate(response);

    if (validate.success) {
      return validate.value;
    } else {
      const message = "Invalid response from server: " + validate.message;
      console.error(message);

      throw new Error(message);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function getTickersQuery({
  limit = 10,
  query,
  exchange,
}: {
  limit?: number;
  query?: string;
  exchange?: string;
}): Promise<Ticker[]> {
  try {
    const response = await request()
      .url(`${TICKERS_V2_ENDPOINT}/query`)
      .query({ limit, ...(query && { query }), ...(exchange && { exchange }) })
      .get()
      .json();

    const validate = TickersQueryResponse.validate(response);

    if (validate.success) {
      return validate.value;
    } else {
      const message = "Invalid response from server: " + validate.message;
      console.error(message);

      throw new Error(message);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function importTickers({ exchange, file }: { exchange: string; file: File }): Promise<void> {
  try {
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("exchange", exchange);

    await request().url(`${TICKERS_V2_ENDPOINT}/import`).body(formData).post().json();
  } catch (error) {
    throw new Error(error);
  }
}

export async function updateTicker({ id, ticker }: { id: string; ticker: UpdateTickerRequest }): Promise<void> {
  try {
    await request().url(`${TICKERS_V2_ENDPOINT}/${id}`).put(ticker).json();
  } catch (error) {
    throw new Error(error);
  }
}

export async function createTicker({ ticker }: { ticker: CreateTickerRequest }): Promise<void> {
  try {
    await request().url(`${TICKERS_V2_ENDPOINT}`).post(ticker).json();
  } catch (error) {
    throw new Error(error);
  }
}
