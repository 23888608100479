import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { AutocompleteInput } from "screens/collection/views/AutocompleteInput";
import { Box, Button, Flex, Highlight, Text } from "@chakra-ui/react";
import { SemanticFocusedAnswer } from "screens/collection/views/SemanticFocusedAnswer";
import { WorkflowProgressBar } from "screens/landing/components/WorkflowProgressBar";
import { formatDistanceToNow } from "date-fns";
import { useConversationDialogUtils } from "./useConversationDialogUtils";
import { useConversationDialogSuggestionLists } from "./useConversationDialogSuggestionLists";
import { useConversationDialogSubmitFunctions } from "./useConversationDialogSubmitFunctions";
import { useProjectParams } from "hooks";
import { useLocation } from "react-router-dom";
import { ConversationContext } from "./ConversationContext";
import { InfoIcon } from "@chakra-ui/icons";

interface Props {
  initialText?: string;
  className?: string;
  inputId: string;
  minWidth?: string;
  afterSubmit?: () => void;
  conversationId?: string;
}

export const ConversationDialogInput = ({ initialText, className, inputId, minWidth, afterSubmit, conversationId }: Props) => {
  const { projectId } = useProjectParams();
  const [questionText, setQuestionText] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { pathname } = useLocation();
  const { onFeedbackModalOpen, setFeedbackValue, isConversationOpen, onConversationClose } = useContext(ConversationContext);

  const {
    hasExceededMaxProjects,
    currentCollectionType,
    maxAllowedProjects,
    currentWorkflowId,
    workflowStatus,
    currentWorkflowCompletionDate,
    canBypassDisabled,
    setCanBypassDisabled,
  } = useConversationDialogUtils();

  const {
    isValidTicker,
    isLoading,
    currentSuggestionList,
    currentSuggestionListType,
    isInputDisabled,
    handleUpdateSourceWeightPreference,
    selectedFocus,
    answerFocusOptions,
    selectedSourceWeightsIndex,
    onResetQuestion,
    debouncedTickerFilter,
  } = useConversationDialogSuggestionLists({
    questionText,
    setQuestionText,
  });

  const { currentSubmitFunction, onSubmitDueDiligenceProject, currentConversationID, conversation } = useConversationDialogSubmitFunctions({
    questionText,
    selectedFocus,
    afterSubmit,
    conversationId,
  });

  useEffect(() => {
    if (currentSuggestionListType !== "tickers") return;

    if (!questionText.startsWith("/")) debouncedTickerFilter(questionText);
  }, [debouncedTickerFilter, currentSuggestionListType, questionText]);

  useEffect(() => {
    setQuestionText("");
  }, [pathname]);

  const onClickFeedback = useMemo(() => {
    return () => {
      setFeedbackValue(questionText);
      setQuestionText("");
      onFeedbackModalOpen();
    };
  }, [questionText, setFeedbackValue, onFeedbackModalOpen]);

  const errorMessage = useMemo(() => {
    const commonStyles = {
      fontSize: "sm",
      bgColor: "orange.100",
      borderRadius: "md",
      p: "8px",
      lineHeight: "1.4rem",
    };

    return (
      <Box className="ch-autocomplete-error" width="100%" pt="5px">
        {hasExceededMaxProjects && (
          <Box {...commonStyles}>
            {`You can only have ${maxAllowedProjects} active ${currentCollectionType?.replace(/_/g, " ")} projects at a time`}
          </Box>
        )}
        {conversation.conversationState !== "action" &&
          !isLoading &&
          questionText.length > 0 &&
          !isValidTicker(questionText) &&
          currentSuggestionList.length === 0 && (
            <>
              <Box borderRadius={"md"} bgColor="white" borderColor={"gray.300"} borderWidth="1px" p="1rem">
                <Text fontSize="sm" lineHeight="1.4rem">
                  <Highlight query={questionText || ""} styles={{ py: "1", whiteSpace: "normal", fontWeight: "bold" }}>
                    {`There are no matching results for ${
                      questionText ? questionText.toUpperCase() : "this ticker"
                    }.  Additional exchanges, indices and equities are being added rapidly and our team can prioritize based on your feedback.`}
                  </Highlight>
                </Text>
                <Button
                  leftIcon={<InfoIcon color="gray.400" />}
                  fontWeight={"normal"}
                  color="gray.600"
                  pt="1rem"
                  variant="link"
                  fontSize="sm"
                  onClick={onClickFeedback}
                  width="100%"
                  justifyContent={"end"}>
                  Information on prioritizing
                </Button>
              </Box>
            </>
          )}
      </Box>
    );
  }, [
    hasExceededMaxProjects,
    maxAllowedProjects,
    currentCollectionType,
    conversation.conversationState,
    isLoading,
    questionText,
    isValidTicker,
    currentSuggestionList.length,
    onClickFeedback,
  ]);

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      const { key } = ev;

      switch (key) {
        case "Escape":
          if ((!questionText || questionText?.length === 0) && isConversationOpen && inputId === "conversation-input") {
            onConversationClose();
          } else {
            onResetQuestion ? onResetQuestion() : setQuestionText("");
          }
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputId, isConversationOpen, onConversationClose, onResetQuestion, questionText]);

  return (
    <Box position="relative">
      {isConversationOpen && !projectId && (
        <Box pl=".5rem" bottom="3rem" zIndex={2} position={"absolute"} width="calc(100% - 5rem)" ml="2.5rem" mr="3rem">
          {errorMessage}
        </Box>
      )}
      <AutocompleteInput
        inputId={inputId}
        externalInputRef={inputRef}
        className={className}
        minWidth={minWidth || "22rem"}
        conversationId={conversationId || currentConversationID}
        value={isInputDisabled ? undefined : questionText}
        onChange={(value) => {
          setQuestionText(value);
        }}
        onReset={onResetQuestion}
        initialText={conversation.conversationState === "action" ? "Respond to Charli..." : initialText}
        suggestedQuestions={conversation.conversationState === "action" || isInputDisabled ? [] : currentSuggestionList}
        isInputDisabled={isInputDisabled}
        isSubmitDisabled={isInputDisabled}
        onSelectOption={({ question, focus, matchFilter }) => {
          if (conversation.conversationState === "action" || projectId || questionText.startsWith("/")) {
            if (focus && ["analytical", "sentiment"].includes(focus)) {
              handleUpdateSourceWeightPreference(focus === "analytical" ? 0 : 1);
            }
            setQuestionText(question);
          } else {
            const stockExchange = focus && ["analytical", "sentiment"].includes(focus) ? undefined : focus;
            matchFilter && onSubmitDueDiligenceProject(matchFilter[0].toUpperCase(), stockExchange, afterSubmit);
            setQuestionText("");
          }
        }}
        onClickSubmit={() => {
          if (isInputDisabled) return;

          const question = questionText.trim();
          currentSubmitFunction(question);
          setQuestionText("");
        }}
        isLoading={isLoading}
        conversationState={conversation.conversationState}
        canBypassDisabled={canBypassDisabled}
        setCanBypassDisabled={setCanBypassDisabled}
      />
      {projectId && (
        <Box height="1rem" width="100%" mt="5px">
          {!isConversationOpen && currentWorkflowId && workflowStatus === "in_progress" ? (
            <Box pr="1rem" pl="10rem" justifyContent={"end"} width="100%">
              <WorkflowProgressBar
                workflowId={currentWorkflowId}
                lastUpdated={formatDistanceToNow(new Date(currentWorkflowCompletionDate), {
                  addSuffix: true,
                  includeSeconds: false,
                })}
                size="compact"
                showInline={false}
              />
            </Box>
          ) : answerFocusOptions ? (
            <Flex justifyContent={"flex-end"}>
              <SemanticFocusedAnswer
                isDisabled={currentSuggestionListType === "commands"}
                answerFocusOptions={answerFocusOptions}
                selectedSourceWeightsIndex={selectedSourceWeightsIndex}
                onSourceWeightPreferenceChange={(index) => handleUpdateSourceWeightPreference(index)}
              />
            </Flex>
          ) : null}
        </Box>
      )}
      {!isConversationOpen && !projectId && (
        <Box ml="4rem" position={"absolute"} width="30rem">
          {errorMessage}
        </Box>
      )}
    </Box>
  );
};
