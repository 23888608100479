import type { ReactNode } from "react";
import React, { useContext, useEffect } from "react";
import { Icon, Box, Stack, useColorModeValue, IconButton, Menu, MenuButton, MenuList, Portal, Tooltip } from "@chakra-ui/react";
import type { IconType } from "react-icons";
import { track } from "api/analytics";
import { useNavigate } from "react-router-dom";
import { ConversationContext } from "screens/thread/ConversationContext";
import { useCollection, useItemSelector, useProjectParams, useSidebarNavigation } from "hooks";
import { SettingsProviderContext } from "screens/panels/settings/SettingsProvider";
import { AddIcon } from "@chakra-ui/icons";
import { TextOverflowTooltip } from "./TextOverflowTooltip";
import { IoEllipsisVertical } from "react-icons/io5";
import { ProjectDeleteButton } from "screens/collection/views/ProjectActions/ProjectDeleteButton";
import { OptionsMenuItem } from "screens/collection/components/OptionsMenuItem";
import { useAddToCharliContext } from "screens/panels/addToCharli/AddToCharliWizard/AddToCharliProvider";

interface Props {
  text?: string;
  tooltip?: string;
  icon?: IconType;
  iconSize?: string;
  screen?: string;
  routeParams?: string[];
  onClick?: () => void;
  analyticsEvent?: string;
  cssClasses?: string[];
  style?: React.CSSProperties;
  isSelected?: boolean;
  isDisabled?: boolean;
  buttonOnClick?: () => void;
  showProjectActionMenu?: boolean;
  additionalButtons?: ReactNode;
  menuProjectId?: string;
}

export const SidebarButton = React.forwardRef<unknown, Props>((props: Props, ref: React.Ref<unknown>) => {
  const {
    text,
    tooltip,
    routeParams,
    icon,
    iconSize = "1.2rem",
    screen,
    onClick,
    analyticsEvent,
    cssClasses,
    style,
    isSelected,
    buttonOnClick,
    showProjectActionMenu = false,
    additionalButtons,
    menuProjectId,
  } = props;
  const { projectFilter, projectId, parentRoute } = useProjectParams();
  const navigate = useNavigate();
  const selectedButtonColor = useColorModeValue("charli.primaryBlue", "#0b678b");
  const buttonColor = useColorModeValue("charli.darkBlue", "primaryButton.colorDarkMode");
  const { onConversationClose, setRequestEntities, setConversationId, setIsAnotherInputFocused } = useContext(ConversationContext);
  const { setIsHeaderTextEditable, setIsEditing, setIsSideNavigationOpen } = useContext(SettingsProviderContext);
  const { resetSelectedItems } = useItemSelector();
  const { setSelectedSidebar, selectedView } = useSidebarNavigation();
  const portfolioProject = useCollection(projectFilter);
  const { onDeleteProjectModalOpen } = useContext(ConversationContext);
  const { onPortfolioSettingsOpen } = useAddToCharliContext();
  const [showActionMenu, setShowActionMenu] = React.useState(false);

  useEffect(() => {
    setSelectedSidebar(portfolioProject?.name || projectFilter || parentRoute || "");
  }, [portfolioProject?.name, projectFilter, parentRoute, setSelectedSidebar, projectId]);

  const onClickHandler = () => {
    if (analyticsEvent) track(analyticsEvent);
    if (onClick) {
      onClick();
    } else if (screen) {
      const params = routeParams && routeParams.length > 0 ? `/${routeParams.join("/")}` : "";
      navigate(`/${screen}${params}`);
    }
    setRequestEntities([]);
    setConversationId("");
    onConversationClose();
    setIsHeaderTextEditable(false);
    setIsEditing(false);
    resetSelectedItems();
    setIsAnotherInputFocused(false);
    setIsSideNavigationOpen(false);
  };

  const isItemSelected = isSelected || screen?.includes(selectedView) || text?.toLowerCase() === selectedView.toLowerCase();

  return (
    <Box
      width="100%"
      cursor="pointer"
      borderRadius="none"
      borderWidth="0"
      backgroundColor="none"
      _hover={{ backgroundColor: "none" }}
      _active={{ backgroundColor: "none" }}
      onClick={onClickHandler}
      onMouseEnter={() => setShowActionMenu(true)}
      onMouseLeave={() => setShowActionMenu(false)}
      className={cssClasses?.join(" ")}
      style={style}>
      <Stack direction="row" align="center" spacing=".3rem" width="100%" justifyContent={"space-between"}>
        <Tooltip offset={[35, 15]} maxWidth={"9rem"} label={tooltip} aria-label={tooltip}>
          <Stack direction={"row"} spacing=".8rem">
            {icon && (
              <Icon
                as={icon}
                fontWeight={isItemSelected ? "bold" : "normal"}
                color={isItemSelected ? selectedButtonColor : buttonColor}
                borderColor={isItemSelected ? selectedButtonColor : buttonColor}
                boxSize={iconSize}
              />
            )}
            {text && (
              <TextOverflowTooltip
                color={isItemSelected ? selectedButtonColor : buttonColor}
                style={{ fontWeight: isItemSelected ? "bold" : "normal" }}
                label={text}
              />
            )}
          </Stack>
        </Tooltip>
        <Stack direction={"row"} spacing="5px">
          {additionalButtons && additionalButtons}
          {buttonOnClick && (
            <Tooltip
              label={projectId && projectId.length > 0 ? "" : "Add a new portfolio to manage your equities"}
              aria-label="Add Portfolio">
              <IconButton
                as="div"
                isDisabled={projectId ? projectId.length > 0 : false}
                className="ch-sidebar-portfolio-button"
                minWidth={"unset"}
                boxSize="1.2rem"
                icon={<AddIcon boxSize={".7rem"} />}
                onClick={(evt) => {
                  evt.preventDefault();
                  buttonOnClick();
                }}
                _hover={{}}
                _active={{}}
                bgColor="primary.default"
                color="white"
                aria-label={""}
              />
            </Tooltip>
          )}
          {showProjectActionMenu && (
            <Menu isLazy>
              {showActionMenu && (
                <MenuButton
                  className="ch-sidebar-options-menu"
                  height={"1rem"}
                  width={"1.2rem"}
                  _hover={{}}
                  _active={{}}
                  variant="ghost"
                  as={IconButton}
                  aria-label="content options"
                  icon={<Icon as={IoEllipsisVertical} _active={{}} _hover={{}} />}
                  onClick={(event) => event.stopPropagation()}
                  minWidth="0"
                />
              )}
              <Portal>
                <MenuList
                  minWidth={"unset"}
                  zIndex={10}
                  onMouseEnter={() => setShowActionMenu(true)}
                  onMouseLeave={() => setShowActionMenu(false)}>
                  <OptionsMenuItem
                    menuLabel="Portfolio Settings"
                    className="ch-open-project-settings"
                    onClick={() => {
                      onPortfolioSettingsOpen();
                    }}
                  />
                  {menuProjectId && (
                    <ProjectDeleteButton
                      label="Delete Portfolio"
                      className="ch-delete-portfolio-sidebar"
                      onOpenConfirmation={onDeleteProjectModalOpen}
                      projectId={menuProjectId}
                      isMenuItem
                      navigatePath={projectFilter === menuProjectId ? "/home" : undefined}
                    />
                  )}
                </MenuList>
              </Portal>
            </Menu>
          )}
        </Stack>
      </Stack>
    </Box>
  );
});
