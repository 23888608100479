import { Box, Button, Stack, useColorModeValue, Text, Icon } from "@chakra-ui/react";
import { parseISO } from "date-fns";
import type { FunctionComponent } from "react";
import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { sendMessage } from "state/websocket/operations";
import type { AcknowledgmentStatus, Message } from "types/conversation";
import { ChatBubble, MessageContainer } from "./components";
import { ConversationContext } from "screens/thread/ConversationContext";
import type { CharliUI } from "types/charliui";
import { ActivityActionButtons } from "screens/landing/tabs/activities/ActivityActionButtons";
import isEmpty from "lodash/isEmpty";
import { BiFolder } from "react-icons/bi";
import { BsArrowLeft } from "react-icons/bs";
import { useButtonProps } from "hooks";

interface Props {
  message: Message;
  acknowledgmentStatus: AcknowledgmentStatus;
  isLastMessage?: boolean;
}

export const TextCell: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
  message,
  isLastMessage = false,
  acknowledgmentStatus,
}) => {
  const dispatch = useDispatch();
  const { conversationId, conversationDialogWidth, onUpgradeModalOpen } = useContext(ConversationContext);
  const isLastMessageAndFromCharli = message.senderId === "charli";
  const inlineActions = useMemo(() => {
    if (
      isLastMessageAndFromCharli &&
      (message.state === "action_required" || message.state === "task_error") &&
      Array.isArray(message.data)
    ) {
      const actions = message.data.find((datum) => datum.type === "inline_action");
      if (actions?.type === "inline_action" && actions.body.inline_action.length > 0) {
        return actions.body.inline_action;
      }
    }
  }, [message, isLastMessageAndFromCharli]);

  const multiChoiceActions = useMemo(() => {
    if (
      isLastMessageAndFromCharli &&
      (message.state === "action_required" || message.state === "task_error") &&
      Array.isArray(message.data)
    ) {
      const actions = message.data.find((datum) => datum.type === "multi_choice_action");
      if (actions?.type === "multi_choice_action" && actions.body.multi_choice_action.length > 0) {
        return actions.body.multi_choice_action;
      }
    }
  }, [message, isLastMessageAndFromCharli]);
  const bgColor = useColorModeValue("secondary.teal", "#1e5f78");
  const bgColorUpgrade = useColorModeValue("orange.300", "orange.300");
  const textColorUpgrade = useColorModeValue("gray.600", "gray.300");

  const showInlineActions = inlineActions && inlineActions?.length > 0 && isLastMessageAndFromCharli;
  const showMultiChoiceActions = multiChoiceActions && multiChoiceActions.length > 0;
  const tileBorderColor = useColorModeValue("gray.300", "gray.700");
  const iconColor = useColorModeValue("gray.500", "gray.700");
  const secondaryButtonProps = useButtonProps("sm", "secondary");

  // inline actions will have an intent, message, or both
  const processAction = (messageText?: string) => {
    if (!messageText) return;
    // Propagate update data callbacks through inline actions
    const data: CharliUI | undefined = message.data?.find((datum) => datum.type === "update_data_callback");

    dispatch(
      sendMessage({
        conversationId,
        message: messageText,
        intent: messageText,
        datum: data ? [data] : undefined,
        disableDebugging: true,
        disableTesting: true,
      })
    );
  };

  return (
    <MessageContainer
      initiator={message.senderId === "charli" ? "charli" : "user"}
      key={`${message.id}-text`}
      minWidth={inlineActions && inlineActions.length > 0 ? "18rem" : undefined}>
      <Box
        position="relative"
        overflow="visible"
        mb={
          showInlineActions || message.state === "out_of_credits" || message.state === "task_error" || showMultiChoiceActions
            ? "0.5rem"
            : "unset"
        }>
        <ChatBubble
          key={message.id}
          state={message.state}
          text={message.content}
          initiator={message.senderId === "charli" ? "charli" : "user"}
          date={parseISO(message.createdDate) ?? new Date()}
          conversationId={conversationId}
          acknowledgmentStatus={acknowledgmentStatus}
        />
        {message.state === "out_of_credits" && (
          <Box position="absolute" overflow="visible" bottom="-.9rem" right="0.6rem">
            <Stack direction="row">
              <Button
                fontWeight={400}
                aria-label="upgrade"
                key={`${message.id}-error`}
                paddingX="0.8rem"
                position="relative"
                backgroundColor={bgColorUpgrade}
                colorScheme="secondary.teal"
                color={textColorUpgrade}
                size="xs"
                onClick={() => {
                  onUpgradeModalOpen();
                }}>
                Upgrade to Business
              </Button>
              {showInlineActions && <ActivityActionButtons conversationId={conversationId} />}
            </Stack>
          </Box>
        )}
        {message.state === "task_error" && message.content && message.content?.length > 15 && (
          <Box position="absolute" overflow="visible" bottom="-.9rem" right="0.6rem">
            <Stack direction="row">
              <Button
                aria-label="support"
                key={`${message.id}-error`}
                paddingX="0.8rem"
                position="relative"
                backgroundColor={bgColor}
                colorScheme="secondary.teal"
                color="white"
                size="xs"
                onClick={() => {
                  window.open("https://support.charli.ai/hc/en-us/requests/new", "_blank", "noopener,noreferrer");
                }}>
                Submit Support Ticket
              </Button>
              {showInlineActions && <ActivityActionButtons conversationId={conversationId} />}
            </Stack>
          </Box>
        )}
        <Box>
          {showMultiChoiceActions && multiChoiceActions && (
            <Stack spacing=".6rem">
              {multiChoiceActions.map((action, i) => {
                if (action.actions && !isEmpty(action.actions)) {
                  return (
                    <Stack
                      key={i}
                      {...(conversationDialogWidth > 600 && {
                        direction: "row",
                      })}
                      width="100%"
                      borderColor={tileBorderColor}
                      borderWidth="1px"
                      borderRadius="md"
                      p="1rem"
                      justifyContent={"space-between"}
                      spacing="0.5rem">
                      <Stack direction="row" spacing=".5rem">
                        {action.actions.some((subAction) => subAction.label?.toLowerCase().includes("expand")) && (
                          <Icon as={BiFolder} boxSize="1.2rem" color={iconColor} />
                        )}
                        <Text fontSize="sm">{action.label}</Text>
                      </Stack>
                      <Stack direction="row" justifyContent={conversationDialogWidth > 600 ? "flex-end" : "flex-start"}>
                        {action.actions.map((subAction) => {
                          return (
                            <Button
                              {...secondaryButtonProps}
                              isDisabled={!isLastMessage}
                              className={`ch-multi-choice-sub-action-${i}`}
                              aria-label={subAction.label}
                              key={subAction.label}
                              onClick={() => {
                                processAction(subAction.message);
                              }}>
                              <Text isTruncated>{subAction.label}</Text>
                            </Button>
                          );
                        })}
                      </Stack>
                    </Stack>
                  );
                } else {
                  return (
                    <Button
                      {...secondaryButtonProps}
                      isDisabled={!isLastMessage}
                      _disabled={{ opacity: 1, cursor: "not-allowed" }}
                      p="1rem"
                      className={`ch-multi-choice-action-${i}`}
                      aria-label={action.label}
                      key={action.label}
                      onClick={() => {
                        processAction(action.message);
                      }}>
                      <Stack direction="row" width="100%" justifyContent={action.label === ".." ? "space-between" : "flex-start"}>
                        {action.label === ".." && <Icon as={BsArrowLeft} boxSize="1.4rem" color={iconColor} />}
                        <Text>{action.label}</Text>
                      </Stack>
                    </Button>
                  );
                }
              })}
            </Stack>
          )}
        </Box>
      </Box>
    </MessageContainer>
  );
};
