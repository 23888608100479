import { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { downloadCollections, downloadInitialCollections } from "state/collection/operations";
import type { RootState } from "state/rootReducer";
import type { Collection, CollectionWithAdditionalProps } from "types/collection";
import uniq from "lodash/uniq";
import { useFilterWorkflowsIdsByIntents } from "./useWorkflows";
import { useLocation, useParams } from "react-router-dom";
import type { StockExchangeRecord } from "types/stock";
import { useCollectionMetadataWithTagId } from "hooks";
import orderBy from "lodash/orderBy";

interface ProjectParams {
  parentRoute?: string;
  projectFilter?: string;
  projectId?: string;
  contentId?: string;
  isPortfolios?: boolean;
  isProjects?: boolean;
}

export const useProjectParams = (): ProjectParams => {
  const { projectFilter, projectId, contentId } = useParams<{
    projectFilter: string;
    projectId: string;
    contentId?: string;
  }>();
  const { pathname } = useLocation();
  const parentRoute = pathname.split("/")[1];

  return useMemo(() => {
    return {
      parentRoute,
      projectFilter,
      projectId,
      contentId,
      isPortfolios: parentRoute === "portfolios",
      isProjects: parentRoute === "projects",
    };
  }, [parentRoute, projectFilter, projectId, contentId]);
};

export const getMainCollectionWorkflowId = (collection: Collection | undefined): string | undefined => {
  return collection?.workflowIds?.[0];
};

export function useCollectionCreatedDate(id: string): string | undefined {
  const collection = useSelector((state: RootState) => (id ? state.collection.collections[id] : undefined), shallowEqual);

  return collection?.metadata.createdTime;
}

export const filterCollectionsByType = (collections: Collection[], type: string): Collection[] => {
  if (type === "all") return collections;
  return collections.filter((collection) => collection.collectionType === type);
};

export function usePortfolioCollections() {
  const collections = useCollections();
  return useMemo(() => filterCollectionsByType(collections, "portfolio"), [collections]);
}

export function useProjectInPortfolios(projectId: string) {
  const portfolioCollections = usePortfolioCollections();
  return useMemo(() => {
    const filterPortfolioCollectionsWhereProjectIdIsInMetadataIds = portfolioCollections.filter((collection) =>
      collection.metadataIds.includes(projectId)
    );

    return filterPortfolioCollectionsWhereProjectIdIsInMetadataIds;
  }, [portfolioCollections, projectId]);
}

/**
 * Returns an array for all collections.
 */
export function useCollections() {
  return useSelector((state: RootState) => {
    return state.collection.order.map((id) => state.collection.collections[id]);
  }, shallowEqual);
}

// export an array of tags from all collections
export function useCollectionTags(tagType: "autoTags" | "manualTags" | "topics" | "all" = "all") {
  const collections = useCollections();
  const tags: string[] = [];

  collections.forEach((collection) => {
    if (collection.metadata.autoTags && (tagType === "autoTags" || tagType === "all")) {
      collection.metadata.autoTags.forEach((tag) => {
        tags.push(tag);
      });
    }
    if (collection.metadata.manualTags && (tagType === "manualTags" || tagType === "all")) {
      collection.metadata.manualTags.forEach((tag) => {
        tags.push(tag);
      });
    }
    if (collection.searchHistory && (tagType === "topics" || tagType === "all")) {
      collection.searchHistory
        .map((search) => search.entities?.filter((entity) => entity.entity === "topic"))
        .forEach((entities) => {
          if (entities) {
            entities.forEach((entity) => {
              if (Array.isArray(entity.value)) {
                const values: string[] = entity.value;
                tags.push(...values);
              }
            });
          }
        });
    }
  });

  return uniq(tags).map((option) => ({ label: option, value: option }));
}

/**
 * Returns data for a given collection.
 * @param {string} id - Collection ID to query. If undefined is provided, undefined will be returned
 * @param options
 */
export function useCollection(
  id?: string,
  options: { refreshFromNetwork: boolean } = { refreshFromNetwork: false }
): CollectionWithAdditionalProps | undefined {
  const collection = useSelector((state: RootState) => (id ? state.collection.collections[id] : undefined), shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && options.refreshFromNetwork) dispatch(downloadCollections({ ids: [id] }));
  }, [dispatch, id, options.refreshFromNetwork]);

  return collection;
}

export function useCollectionKey<T extends keyof Collection>(collectionId: string | undefined, key: T): Collection[T] | undefined {
  return useSelector(
    (state: RootState) =>
      collectionId && state.collection.collections[collectionId] ? state.collection.collections[collectionId][key] : undefined,
    shallowEqual
  );
}

export function useProjectGroupIdToGetMetaDataIds(projectGroupId: string | undefined): string | undefined {
  const collections = useCollections();

  const lastProjectGroupCollection = useMemo(() => {
    if (!projectGroupId) return undefined;
    return collections.filter((collection) => collection.projectGroupId === projectGroupId).pop();
  }, [collections, projectGroupId]);

  const metadataIds = lastProjectGroupCollection?.metadataIds ?? [];
  const logoContent = useCollectionMetadataWithTagId(metadataIds, "company_logo");

  return useMemo(() => {
    if (!projectGroupId) return undefined;
    return logoContent;
  }, [projectGroupId, logoContent]);
}

export function useCollectionHasHighlights(collectionId: string): boolean {
  const extractedHighlights = useCollectionKey(collectionId, "extractedHighlights");

  return useMemo(
    () => (extractedHighlights && extractedHighlights[0]?.highlights[0]?.highlight?.length > 0) || false,
    [extractedHighlights]
  );
}

export function useCollectionWorkflowsIds(collectionId: string | undefined): string[] {
  return useSelector(
    (state: RootState) => (collectionId ? state.collection.collections[collectionId]?.workflowIds || [] : []),
    shallowEqual
  );
}

export const useIsLoadingCollections = (): boolean => {
  return useSelector((state: RootState) => state.collection.isLoading);
};

export const useIsInitialCollectionSyncCompleted = (): boolean => {
  return useSelector((state: RootState) => state.collection.hasInitialSyncCompleted);
};

export const useCollectionByTypeCount = (collectionType?: string): number | undefined => {
  return useSelector((state: RootState) => (collectionType ? state.collection.collectionsByTypeCount[collectionType] : undefined));
};

export const useGroupCollectionsIds = (groupId: string): string[] | undefined => {
  return useSelector(
    (state: RootState) => state.collection.collectionsIdsByGroup && state.collection.collectionsIdsByGroup[groupId],
    shallowEqual
  );
};

export const useSortedCollectionsIds = (collectionIds: string[] | undefined): string[] | undefined => {
  return useSelector((state: RootState) => {
    return collectionIds
      ? orderBy(collectionIds, (id) => new Date(state.collection.collections[id]?.metadata.createdTime), "desc")
      : undefined;
  }, shallowEqual);
};

export const useTotalCollectionsByType = (collectionType?: string): number => {
  return useSelector((state: RootState) => {
    return collectionType
      ? state.collection.order.filter((id) => state.collection.collections[id].collectionType === collectionType).length
      : 0;
  });
};

export const useLatestCollectionWorkflowId = (collectionId: string | undefined, includedIntents?: string[]): string | undefined => {
  const workflowsIds = useCollectionWorkflowsIds(collectionId);
  const filteredWorkflowsIdsByIntent = useFilterWorkflowsIdsByIntents(workflowsIds, includedIntents);

  return filteredWorkflowsIdsByIntent.length > 0 ? filteredWorkflowsIdsByIntent[filteredWorkflowsIdsByIntent.length - 1] : undefined;
};

export const useCollectionWorkflowIdInProgress = (collectionId: string | undefined): string | undefined => {
  const workflowsIds = useCollectionKey(collectionId, "workflowIds");
  return useSelector((state: RootState) => {
    if (workflowsIds) {
      return workflowsIds.find((id) => {
        const workflow = state.workflow.workflowsById[id];
        return workflow && workflow.status === "in_progress";
      });
    }
  }, shallowEqual);
};

export const useFilterCollectionInProgress = (collectionIds: string[]): string | undefined => {
  return useSelector((state: RootState) => {
    const inProgress = collectionIds.find((id) => {
      const workflowsIds = state.collection.collections[id]?.workflowIds;
      if (workflowsIds) {
        return workflowsIds.find((id) => {
          const workflow = state.workflow.workflowsById[id];
          return workflow && workflow.status === "in_progress";
        });
      } else {
        return false;
      }
    });

    return inProgress;
  }, shallowEqual);
};

export const useCollectionType = (collectionId: string): string | undefined => {
  return useSelector((state: RootState) => state.collection.collections[collectionId]?.collectionType);
};

export const useMainCollectionWorkflowId = (collectionId: string | undefined, includedIntents?: string[]): string | undefined => {
  const workflowsIds = useCollectionWorkflowsIds(collectionId);
  const filteredWorkflowsIdsByIntent = useFilterWorkflowsIdsByIntents(workflowsIds, includedIntents);

  return filteredWorkflowsIdsByIntent[0];
};

export const useCollectionCancelWorkflowId = (collectionId: string | undefined): string | undefined => {
  const workflowsIds = useCollectionWorkflowsIds(collectionId);
  return useFilterWorkflowsIdsByIntents(workflowsIds, ["cancel"])[0];
};

export const useDownloadInitialCollections = ({ homeLimit, portfolioLimit }: { homeLimit?: number; portfolioLimit?: number }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(downloadInitialCollections({ homeLimit, portfolioLimit }));
  }, [dispatch, homeLimit, portfolioLimit]);
};

export const useCollectionStock = (collectionId: string | undefined): StockExchangeRecord | undefined => {
  const maybeCompanyName = useCollectionKey(collectionId, "companyName");
  const maybeTicker = useCollectionKey(collectionId, "ticker");
  const maybeStockExchange = useCollectionKey(collectionId, "stockExchange");
  const maybeName = useCollectionKey(collectionId, "name");

  const companyName = maybeCompanyName || maybeName;

  return useMemo(() => {
    if (companyName && maybeTicker && maybeStockExchange) {
      return {
        name: companyName,
        exchange: maybeStockExchange,
        ticker: maybeTicker,
      };
    } else {
      return undefined;
    }
  }, [companyName, maybeTicker, maybeStockExchange]);
};
