import React, { useEffect } from "react";
import type { PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { ALL, FACEBOOK_PIXEL, page } from "api/analytics";

const cleanPath = (path: string) => {
  if (path.startsWith("/conversation/")) {
    return "/conversation/[conversation-id-redacted]";
  } else {
    return path;
  }
};

export const Analytics: React.FC<React.PropsWithChildren<React.PropsWithChildren<PropsWithChildren>>> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const cleanedPath = cleanPath(location.pathname + location.search);

    page(cleanedPath);

    if (location.pathname === "/register") {
      page(cleanedPath, new Set([FACEBOOK_PIXEL]), new Set([ALL]));
    }
  }, [location]);

  return <>{children}</>;
};
